"use strict";

import { utl_hash_string_to_hex }  from "../util";
import $                    from "jquery";
import Feedback             from "../classes/Feedback";
import Application          from "../main/Application";

export default class CFTurnstileService {
    /**
     * Parent application instance.
     * 
     * @var {Application|null} app
     */
    app = null;

    /**
     * Is CloudFlare turnstile ready?
     * @var {Boolean} turnstileReady
     */
    turnstileReady = false;

    /**
     * The latest returned Turnstile token.
     * @var {String|null} latestTurnstileToken
     */
    latestTurnstileToken = null;

    /**
     * Has the latest token been consumed? If so it needs regeneration, if not then we pass with the current token.
     * @var {Boolean} latestTurnstileTokenConsumed 
     */
    latestTurnstileTokenConsumed = true;

    /**
     * Called when turnstile is ready.
     * @var {Function|null} readyCallback
     */
    readyCallback = null;

    /**
     * The ID of the frame to use for rendering the Turnstile box.
     * @var {string|null}
     */
    turnstileFrameId = null;

    /**
     * Constructor
     * 
     * @param {App} app 
     */
    constructor( app, readyCallback = null, turnstileFrameId = "#turnstile-frame" ) {
        this.app                = app;
        this.readyCallback      = readyCallback;
        this.turnstileFrameId   = turnstileFrameId;

        // if ( ! document.querySelector( this.turnstileFrameId ) ) {
        //     console.info( "CFTurnstileService: Turnstile could not be initialised. No container." )
        //     return;
        // }

        // // Hash the function name to expose to window.
        // // This will eventually be updated to just use the hash and no trace of the method name will be included. We can then just hash the known method name to see it within the DOM.
        // utl_hash_string_to_hex( "__turnStileReady" ).then( ( function ( res ) { 
        //     console.log( res );
        //     window[ res ] = this.onTurnstileReady.bind( this );
        // } ).bind( this ) );
    }

    /**
     * Called when turnstile is ready.
     * @return {void}
     */
    onTurnstileReady() {
        this.turnstileReady = true;

        // Feedback.notice( "CFTurnstileService: Turnstile ready." );

        // if ( this.readyCallback ) {
        //     this.readyCallback();
        // }
    }

    /**
     * Gets the turnstile token, will return into `cb`.
     * 
     * @param {Function} cb 
     * @return {String} The ID of the executable container.
     */
    getToken( cb ) {
        cb( null );
        return this.turnstileFrameId;

        // console.info( `CFTurnstileService: Request to generate new Turnstile verification token...` );
        // if ( ! this.latestTurnstileTokenConsumed ) {
        //     this.latestTurnstileTokenConsumed = true;

        //     cb( this.latestTurnstileToken );
        //     return null;
        // }

        // const id = window.turnstile.render( this.turnstileFrameId, { 
        //     sitekey:    window[ "3301cef3b378b774f23645b1367a2a73e3f456d5950d20622dbc3d79766594b0" ],
        //     callback:   ( function ( token ) {
        //         this.latestTurnstileToken = token;
        //         this.latestTurnstileTokenConsumed = false;
        //         console.info( `CFTurnstileService: Turnstile token: ${ this.latestTurnstileToken }` );

        //         cb( token );
                
        //         // Clear the frame so the customer only sees it flash by.
        //         window.turnstile.remove( id );
        //     } ).bind( this)
        // } );

        // return id;
    }
}