"use strict";

export default class Project {
    /**
     * The reference code.
     * @var {string|null}
     */
    reference = null;

    /**
     * The name of the project.
     * @var {string|null}
     */
    name = null;

    /**
     * The template story of the project, as set by admin.
     * @var {string|null}
     */
    story = null;

    /**
     * @var {Array<Object>}
     */
    givingLevels = [];

    isOneOff = true;
    isMonthly = true;
    isWeekly = true;
    isMonthly = true;
    isYearly = true;
    minimumDonationAmount = 1;
    maximumDonationAmount = 999999;
    donationUnit = 0.01;
    subProjects = [];

    /**
     * Class constructor
     * @param {string|null} reference
     * @param {string|null} name
     * @param {string|null} story
     */
    constructor ( 
        reference       = null,
        name            = null,
        story           = null,
        givingLevels    = null
    ) {
        this.reference      = reference;
        this.name           = name;
        this.story          = story;
        this.givingLevels   = givingLevels;
    }

    /**
     * Construct a new Donor instance from any object by copying the properties.
     * @param {Object} object 
     */
    static constructFrom( object ) {
        const project = new Project;
        const keys = Object.keys( object );
        const possibleKeys = [
            "reference", 
            "name", 
            "story", 
            "givingLevels", 
            "isOneOff", 
            "isDaily", 
            "isWeekly", 
            "isMonthly", 
            "isYearly",
            "minimumDonationAmount",
            "maximumDonationAmount",
            "donationUnit"
        ];

        // Copy object in.
        for ( let idx in possibleKeys ) {
            const possibleKey = possibleKeys[ idx ];
            if ( keys.includes( possibleKey ) ) {
                project[ possibleKey ] = object[ possibleKey ];

                if ( [ "isOneOff", "isDaily", "isWeekly", "isMonthly", "isYearly" ].includes( possibleKey ) ) {
                    project[ possibleKey ] = Boolean( object[ possibleKey ] );
                }
            }
        }

        if ( object[ "subProjects" ] ) {
            Object.keys( object[ "subProjects" ] ).forEach( reference => {
                const subProject = object.subProjects[ reference ];
                project.subProjects.push( Project.constructFrom( subProject ) );
            } );
        }

        return project;
    }
}