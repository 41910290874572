"use strict";

import Application          from "../main/Application";
import NetworkTransport from "../classes/NetworkTransport";

export default class DeleteAddressService {
    /**
     * Parent application instance.
     * @var {Application|null} app
     */
    app = null;

    /** 
     * @var {CFTurnstileService|null} cf
     */
    cf = null;

    /**
     * Constructor.
     * 
     * @param {Application|null} app 
     */
    constructor ( app, CFTurnstileServiceInstance ) {
        this.app = app;
        this.cf = CFTurnstileServiceInstance;
    }
    
    /**
     * Deletes an address.
     * @param {*} addressUUID 
     * @returns {Promise<{status:boolean,error:string|null|undefined}>}
     */
    async deleteAddress( addressUUID ) {
        // Generate new Turnstile token.
        await this.cf.getToken( () => {} );
        const tsToken = this.cf.latestTurnstileToken;
        this.cf.latestTurnstileTokenConsumed = true;

        // Attempt to delete the address.
        return new NetworkTransport( {
            address_uuid:   addressUUID,
            _tsToken:       tsToken
        } )
            .sendTo( "delete-address" );
    }
}