"use strict";
/**
 * NOTE: A bug in [git / sourcetree / my understanding] results in anything named app.js or app.css (case insensitve) being automatically excluded from our projects.
 */


/**
 * Import vendor packages.
 */
import $                                from "jquery";
import Alpine                           from "alpinejs";

/**
 * Import "service" classes.
 */
import CustomFieldsService              from "../services/CustomFieldsService";
import StoreCustomerService             from "../services/StoreCustomerService";
import GoCardlessOneOffService          from "../services/GoCardlessOneOffService";
import PayPalOneOffService              from "../services/PayPalOneOffService";
import StripeOneOffService              from "../services/StripeOneOffService";
import GoCardlessSubscriptionService    from "../services/GoCardlessSubscriptionService";
import PayPalSubscriptionService        from "../services/PayPalSubscriptionService";
import StripeSubscriptionService        from "../services/StripeSubscriptionService";
import CFTurnstileService               from "../services/CFTurnstileService";
import GoogleAutocompleteService        from "../services/GoogleAutocompleteService";
import DeleteAddressService             from "../services/DeleteAddressService";
import AppealCreateService              from "../services/AppealCreateService";
import AppealSlugCheckService           from "../services/AppealSlugCheckService";

/**
 * Import controller classes.
 */
import FlowController                   from "../controllers/DonationFlowController";
import AppealCreateFlowController       from "../controllers/AppealCreateFlowController";
import PhoneController                  from "../controllers/PhoneController";
import StripeController                 from "../controllers/StripeController";
import GoCardlessController             from "../controllers/GoCardlessController";
import PayPalController                 from "../controllers/PayPalController";
import ReelerController                 from "../controllers/ReellerController";
import TextEditorController             from "../controllers/TextEditorController";
import GlobalSliderController           from "../controllers/GlobalSliderController";
import ModalController                  from "../controllers/ModalController";

/**
 * Import Alpine data classes.
 */
import DonationData                     from "../alpine/DonationData";
import DonationCompletedData            from "../alpine/DonationCompletedData";
import AppealCreateData                 from "../alpine/AppealCreateData";

/**
 * Generic classes.
 */
import Donor                            from "../classes/Donor";
import DonorAddress                     from "../classes/DonorAddress";
import Project                          from "../classes/Project";

/**
 * Utilities.
 */
import { 
    utl_patch_number_input, 
    utl_patch_number_input_field, 
    utl_set_config_var 
}                                       from "../util";

// Setup for window-exposed variables.
import "./Window";
import MailingListController from "../controllers/MailingListController";


/**
 * JS application entrypoint.
 */
export default class Application { 
    /**
     * Our stripe configuration.
     * 
     * @var {{
     *  public_key: string|null 
     * }|null}
     */
    stripeConfig = null;

    /**
     * Our payments configuration (i.e. minimum payment or subscription amount)
     * 
     * @var {{}}
     */
    paymentConfig = null;

    /**
     * Service handlers.
     * 
     * @type {Object}
     */
    services = {};

    /**
     * @type {FlowController|null}
     */
    donatePage = null;

    /**
     * @type {FlowController|null}
     */
    donateCompletedPage = null;

    /**
     * @type {FlowController|null}
     */
    appealCreatePage = null;

    /**
     * @type {GoogleAutocompleteService|null}
     */
    autocompleteService = null;

    /**
     * @type {google.maps.places.AutocompleteOptions|null}
     */
    autocomplete = null;

    /**
     * @type {StripeController|null}
     */
    stripeController = null;

    /**
     * @type {PayPalController|null}
     */
    payPalController = null;

    /** 
     * @type {GoCardlessController|null}
     */
    goCardlessController = null;

    /**
     */
    textEditorController = null;
    
    /**
     * @type {ReelerController|null}
     */
    reelerController = null;

    /**
     * @type {GlobalSliderController|null}
     */
    globalSliderController = null;

    /**
     * @type {ModalController|null}
     */
    modalController = null;

    /**
     * @type {PhoneController|null}
     */
    phoneController = null;

    /**
     * @type {MailingListController}
     */
    mailingListController = null;

    /**
     * Our customers information.
     * 
     * @var {{
     *  reference: string|null, 
     *  address_reference: string|null
     * }}
     */
    customer = {
        reference:          null,
        address_reference:  null
    };

    /**
     * If the user is logged in, or authenticated as a Donor already, this is populated with their information.
     * @var {Donor|null}
     */
    currentDonor = null;

    /**
     * If the user is logged in, or authenticated as a Donor already, this is populated with their previous addresses.
     * @var {Array<DonorAddress>|null}
     */
    currentDonorAddresses = null;

    /**
     * Injected available project information.
     * @var {Object<string,Project>|null}
     */
    projectData = null;


    /**
     * PayPal subscription object
     * 
     * @var {Object}
     */
    payPalSubscription = {
        productId:  null,
        planId:     null
    };

    /**
     * Is cloudflare Turnstile initialised?
     * 
     * @var {Boolean} turnstileReady
     */
    turnstileReady = false;

    /**
     * The entry point.
     */
    constructor( shouldCleanDOM = true ) {
        console.log( `Application startup (shouldCleanDOM = ${ shouldCleanDOM ? 'true' : 'false' }): `, this );

        this.setupCurrentDonor( shouldCleanDOM );
        this.setupCurrentDonorAddresses( shouldCleanDOM );
        this.setupProjects( shouldCleanDOM );

        if ( ! window[ "77d9bce4a13de264ff0526e2f26923e29ecf2de9f156d077ff6408bfc352007a" ] ) {
            console.error( "CRITICAL: Locale has been corrupted!" );
            debugger;
            return;
        }

        utl_set_config_var( "locale", window[ "77d9bce4a13de264ff0526e2f26923e29ecf2de9f156d077ff6408bfc352007a" ] );
        utl_set_config_var( "defaultCurrency", window[ "0bca6e8e6909d333f68af7bfbfe27ee548410c798cfa3b422235181a218702b2" ] );
        utl_set_config_var( "9e005dbefd7972026ed111235c2a74afc9ed4173ed2f1b13522b3dbbeb78770c", this );

        delete window[ "77d9bce4a13de264ff0526e2f26923e29ecf2de9f156d077ff6408bfc352007a" ];
        delete window[ "0bca6e8e6909d333f68af7bfbfe27ee548410c798cfa3b422235181a218702b2" ];

        $( this.onReady.bind( this ) );

        // Setup globals.
        window[ "_App" ] = this;                                                                                            // Expose our application to the DOM.
        window[ "53fcc18c24b551b589b01d8d0cfc270607c00c11eceb7eb25a5bbb38ff7cb078" ] = () => {};                            // Suppress Cloudflare Turnstile warnings, this value is overriden on actual initialisation.
        
        // Steup services, controllers, options.
        this.stripeConfig = { public_key: window[ "8b6ce711799469961a411e9788b7fcfaa83fd739982ea0977b0e34f490caa9f5" ] };   // Grab the Stripe public key.
        this.paymentConfig = window[ "278f1640ed2658cc266b13dd0415211538deb89816b6373cecf25ce4a1f76d3d" ];                  // Grab the settings-defined payments config.
        this.makeService( "CFTurnstileService", CFTurnstileService, this.onTurnstileReady.bind( this ) );                   // Special service.. Can be preloaded.
        
        console.log( "Ajshasdkhaskdjahskjdhaskjdh", this.paymentConfig );

        // Setup "controllers".
        this.textEditorController   = new TextEditorController( this );   // Calls own onReady method.
        this.reelerController       = new ReelerController( this );         // ^
        this.globalSliderController = new GlobalSliderController( this );   // ^
        this.modalController        = new ModalController( this );          // ^
        this.phoneController        = new PhoneController( this );          // ^

        console.log( "TextEditorController: ", this.textEditorController );
        console.log( "ReellerController: ", this.reelerController );
        console.log( "GlobalSliderController: ", this.globalSliderController );
        console.log( "ModalController: ", this.modalController );
        console.log( "PhoneController: ", this.phoneController );

        this.setupAutocomplete();
        this.setupDonationApp();
        this.setupDonationCompletedApp();
        this.setupAppealCreationApp();
        this.setupFooterMailSignon();
    }

    /**
     * Called when the document is ready.
     */
    onReady() {
        this.initialiseRequiredServices();

        if ( this.paymentConfig ) {
            this.setupPaymentConfig();
        }

        // If the stripe configuration is valid then load it up, otherwise display warning message.
        if ( this.stripeConfig?.public_key ) {
            this.stripeController = new StripeController( this, this.stripeConfig );
        }

        this.payPalController = new PayPalController( this );
        this.goCardlessController = new GoCardlessController( this );

        // Fixes un-wanted behaviour in all input[type="number"] fields.
        utl_patch_number_input();
        utl_patch_number_input_field();

        console.log( `Application startup complete: `, this );
    }
    
    /**
     * Called when turnstile is loaded.
     */
    onTurnstileReady() {
        this
            .services
            .CFTurnstileService
            .getToken( this.onInitialTurnstileCompleted.bind( this ) );
    }

    /**
     * Called when CF turnstile is initialised.
     */
    onInitialTurnstileCompleted() { }

    /**
     * Initialises the services the system requires, based on inputs.
     */
    initialiseRequiredServices() {
        this.makeService( "CustomFieldsService", CustomFieldsService );
        this.makeService( "StoreCustomerService", StoreCustomerService );
        this.makeService( "StripeOneOffService", StripeOneOffService, this.stripe );
        this.makeService( "PayPalOneOffService", PayPalOneOffService );
        this.makeService( "GoCardlessOneOffService", GoCardlessOneOffService );
        this.makeService( "StripeSubscriptionService", StripeSubscriptionService, this.stripe );
        this.makeService( "PayPalSubscriptionService", PayPalSubscriptionService, this.stripe );
        this.makeService( "GoCardlessSubscriptionService", GoCardlessSubscriptionService, this.stripe );
        this.makeService( "DeleteAddressService", DeleteAddressService, this.services.CFTurnstileService );
        this.makeService( "AppealCreateService", AppealCreateService, this.services.CFTurnstileService );
        this.makeService( "AppealSlugCheckService", AppealSlugCheckService );
    }

    /**
     * Assumption is made that the relevant configuration has been passed to the DOM, and is available at this.paymentConfig
     */
    setupPaymentConfig() {
        if ( this.paymentConfig[ "one-off" ] ) {
            this.setupOneOffPaymentsConfig( this.paymentConfig[ "one-off" ] );
            console.info( "Payment configuration loaded: ", this.paymentConfig[ "one-off" ] );
        }

        if ( this.paymentConfig[ "subscriptions" ] ) {
            this.setupSubscriptionsConfig( this.paymentConfig[ "subscriptions" ] );
            console.info( "Subscription configuration loaded: ", this.paymentConfig[ "subscriptions" ] );
        }
    }

    /**
     * Sets up our page ready to take one-off payments.
     * 
     * @param {{
     *  enabled: boolean,
     *  stripe_enabled: boolean,
     *  paypal_enabled: boolean,
     *  gocardless_enabled: boolean,
     *  minimum_payment: float
     * }} cfg 
     */
    setupOneOffPaymentsConfig( cfg ) {
        const $donation__reoccurrence = $( "#donation--reoccurrence" );
        const $donation__amount = $( "#donation--amount" );

        // Set the initial value.
        if ( $donation__reoccurrence.val() === "one-off" ){
            $donation__amount.attr( "min", cfg.minimum_payment );
        }

        // Bind change event to update value.
        $donation__reoccurrence.on( "change", ( function ( event ) {
            const element = event.target;

            if ( element.value === "one-off" ) {
                $donation__amount.attr( "min", cfg.minimum_payment );
                this.constrainDonationAmount();
            }
        } ).bind( this ) );
    }

    /**
     * Sets up our flow ready to take subscriptions.
     * 
     * @param {{
     *  enabled: boolean,
     *  stripe_enabled: boolean,
     *  paypal_enabled: boolean,
     *  gocardless_enabled: boolean,
     *  minimum_subscription: float
     * }} cfg 
    */
    setupSubscriptionsConfig( cfg ) {
        const $donation__reoccurrence = $( "#donation--reoccurrence" );
        const $donation__amount = $( "#donation--amount" );

        // Set the initial value.
        if ( $donation__reoccurrence.val() !== "one-off" ){
            $donation__amount.attr( "min", cfg.minimum_subscription );
        }

        // Bind change event to update value.
        $donation__reoccurrence.on( "change", ( function ( event ) {
            const element = event.target;

            if ( element.value !== "one-off" ) {
                $donation__amount.attr( "min", cfg.minimum_subscription );
                this.constrainDonationAmount();
            }
        } ).bind( this ) );
    }

    /**
     * Sets up the google autocompletion service.
     */
    setupAutocomplete() { 
        // Google autocompletion service.
        if ( ! document.querySelector( "[load-google-autocomplete]" ) ) {
            return;
        }

        this.autocompleteService = new GoogleAutocompleteService( this );
        console.info( "AutocompleteService: ", this.autocompleteService );
    }

    /**
     * Sets up the current authenticated donors data.
     */
    setupCurrentDonor( scrub = true ) { 
        if ( ! document.querySelector( "script[donor-data]" ) ) {
            return;
        }

        /** @type {Object|null} rawData */
        const rawData = window[ "donorData" ];
        if ( ! rawData ) { 
            return;
        }

        this.currentDonor = Donor.constructFrom( rawData );
        console.info( "CurrentDonor: ", this.currentDonor );

        if ( ! scrub ) {
            return;
        }

        // Delete the original data.
        delete window[ "donorData" ];
        $( `script[donor-data]` ).remove();
    }

    /**
     * Sets up the authenticated donors address data.
     */
    setupCurrentDonorAddresses( scrub = true ) {
        if ( ! document.querySelector( "script[donor-address-data]" ) ) {
            return;
        }

        /** @type {Array<Object>|null} rawData */
        const rawData = window[ "donorAddressData" ];
        if ( ! rawData ) {
            return;
        }

        this.currentDonorAddresses = rawData.map( item => DonorAddress.constructFrom( item ) );
        console.info( "CurrentDonorAddress: ", this.currentDonorAddresses );
        
        if ( ! scrub ) {
            return;
        }

        // Delete the original data.
        delete window[ "donorAddressData" ];
        $( `script[donor-address-data]` ).remove();
    }

    /**
     * Sets up the available projects list.
     */
    setupProjects( scrub = true ) { 
        if ( ! document.querySelector( "script[project-data]" ) ) {
            return
        }

        const rawData = window[ "projectData" ];
        if ( ! rawData ) {
            return;
        }

        this.projectData = {};   
        for ( let idx in Object.keys( rawData ) ) {
            const ref = Object.keys( rawData )[ idx ];
            this.projectData[ ref ] = Project.constructFrom( rawData[ ref ] );
        }

        console.info( "ProjectData: ", this.projectData );

        if ( ! scrub ) {
            return;
        }
        
        // Delete the original data.
        delete window[ "projectData" ];
        $( `script[project-data]` ).remove();
    }

    /**
     * Sets up the main donation application.
     */
    setupDonationApp() { 
        // Main donation application.
        if ( ! document.querySelector( "#donation-app" ) ) {
            return;
        } 

        this.donatePage = new FlowController( this, "donation-app" );
        console.info( "DonatePage: ", this.donatePage );

        this.donatePage.paymentConfig = this.paymentConfig;

        // Alpine picks this up once Alpine.start is called.
        window[ "DonationData" ] = function () {
            return DonationData;
        };
    }

    /**
     * Sets up the donation completion pages.
     */
    setupDonationCompletedApp() {
        // Donation finalised app.
        if ( ! document.querySelector( "#donation-completed-app" ) ) {
            return;
        }

        // this.donateCompletedPage = new FlowController( this, "donation-completed-app" );
        console.info( "DonateCompletedPage: ", this.donateCompletedPage );

        // Alpine picks this up once Alpine.start is called.
        window[ "DonationCompletedData" ] = function () {
            return DonationCompletedData;
        };
    }

    /**
     * Sets up the appeal creation application.
     */
    setupAppealCreationApp() {
        // Create appeal app.
        if ( ! document.querySelector( "#appeal-create-app" ) ) {
            return;
        }

        this.appealCreatePage = new AppealCreateFlowController( this, "appeal-create-app" );
        console.info( "AppealCreatePage: ", this.appealCreatePage );

        window[ "AppealCreateData" ] = function () {
            return AppealCreateData;
        }
    }

    /**
     * Sets up the footer mail signon widget.
     */
    setupFooterMailSignon() { 
        // Locate the container to ensure it's on the page.
        const $container = $( "#footer-mail-signup" );
        if ( ! $container.length ) {
            return null;
        }

        this.mailingListController = new MailingListController( this, "footer-mail-signup" );
        console.info( "MailingListController: ", this.mailingListController );

        return this.mailingListController;
    }

    /**
     * Ensures our donation amount falls within the bounds it's allowed to.
     */
    constrainDonationAmount() {
        const $donation__amount = $( "#donation--amount" );
        const min = $donation__amount.prop( "min" );
        const max = $donation__amount.prop( "max" );
        const currentVal = $donation__amount.val();

        if ( currentVal < min ) {
            $donation__amount.val( min );
        }

        if ( currentVal > max ) {
            $donation__amount.val( max );
        }
    }

    /**
     * Called when there's an error 
     * 
     * @param {*} error 
     */
    onInitialisationFailure( error ) {
        console.error( error );
        // Do something...?
    }

    /**
     * Creates a service, and returns it.
     * 
     * @template {T}
     * @param {T} serviceClass 
     * @return {T} The generated service.
     */
    makeService( name, serviceClass, ...args ) {
        this.services[ name ] = new serviceClass( this, ...args );
        console.info( `SERVICE: ${ name }: `, this.services[ name ] );
        return this.services[ name ];
    }

    /**
     * For setting donation data.
     * @returns {Proxy<DonationData>}
     */
    getDonationProxy() {
        return Alpine.$data( $( "#donation-app" ).get( 0 ) );
    }

    /**
     * Get a project by reference code, if it's available.
     * @param {string} reference_code 
     * @returns {Project|null}
     */
    getProject( reference_code ) {
        return this.projectData[ reference_code ];
    }
}