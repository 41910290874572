"use strict";

/**
 * util.js - Defines a set of utility methods for the JS scripts.
 */

import Alpine from "alpinejs";
import $ from "jquery";
import Application from "./main/Application";
import NetworkTransport from "./classes/NetworkTransport";
import APIStatic from "./classes/APIStatic";

/**
 * "Global" app config.
 */
const configuration = {
    "hashAlgo":         "SHA-256",
    "locale":           "en-GB",
    "defaultCurrency":  "GBP",

    // Application instance.
    "9e005dbefd7972026ed111235c2a74afc9ed4173ed2f1b13522b3dbbeb78770c": null
};

/**
 * Sets a configuration value, for the above table.
 * @param {string} key 
 * @param {string} value 
 */
export function utl_set_config_var( key, value ) {
    configuration[ key ] = value;
    console.info( `utl_set_config_var: ${ key } = ${ value }` ); // This gets scrubbed out on main build.
}

/**
 * Grabs the application instance, as there should only be one.
 * @returns {Application}
 */
export function utl_get_app() {
    return configuration[ "9e005dbefd7972026ed111235c2a74afc9ed4173ed2f1b13522b3dbbeb78770c" ];
}

/**
 * 
 * @param {string} endpoint 
 * @param {any} data 
 * @param {string} method 
 */
export async function utl_test_api( endpoint, data, method = "POST" ) { 
    if ( ! method ) {
        method = "POST";
    }

    return ( new NetworkTransport( data ) ).sendTo( endpoint );
}

/**
 * Uploads a file, instantly.
 * @param {string} folder 
 * @param {Function} savedCb 
 * @param {Function} onStartCb
 * @returns {HTMLInputElement}
 */
export function utl_instant_file_upload( folder = null, savedCb = null, uploadStartedCb = null ) {
    /**
     * Create an input element to take a single file of an image type.
     * 
     * @type {HTMLInputElement} input
     */
    const input = document.createElement( "input" );
    input.type      = "file";
    input.style     = "display: none";
    input.max       = 1;
    input.accept    = ".jpg, .jpeg, .png, .webp";
    input.multiple  = false;

    // Once the file has been changed then we take the data and immediately upload to the server.
    /**
     * 
     * @this {HTMLInputElement}
     * @param {InputEvent} event
     */
    const fileChangeCallback = function ( event ) {
        if ( ! this.files || ! this.files.length ) {
            console.warn( "utl_instant_file_upload: No files added!" );
            return;
        }

        if ( this.files.length > 1 ) {
            console.warn( "Only one file upload supported, and multiple selected!" );

            return;
        }

        const file = this.files[ 0 ];
        const formData = new FormData(); // For POSTing to the backend.
        formData.append( "file", file );
        formData.append( "_token", APIStatic.csrf() );

        // Add in folder
        if ( folder ) {
            formData.append( "folder", folder );
            console.info( `Targetting folder: ${ folder }` );
        }

        if ( uploadStartedCb ) uploadStartedCb();

        // Run an asynchronous request to the upload-file endpoint.
        fetch( APIStatic.endpoint( "upload-file" ), {
            method: "POST",
            body: formData
        } )
            .then( response => response.json() )
            .then( savedCb );
    };

    input.addEventListener( "change", fileChangeCallback );
    input.dispatchEvent( new MouseEvent( "click" ) ); // Run a fake click event so the upload window shows.

    // Return the input element in case the caller needs it, for whatever reason.
    return input;
}

/**
 * Hashes a string to a hexadecimal value.
 * @param {String} str 
 * @param {String} algo
 * @returns {String} The hashed version of `str`.
 */
export async function utl_hash_string_to_hex( str, algo = "SHA-256" ) {
    const data = ( new TextEncoder() ).encode( str );                                   // Encodes the string into a UTF-8 byte array.
    const hashBuffer = await crypto.subtle.digest( algo, data );                        // This bit actually does the hashing. It is asynchronous as it is a slow blocking call.
    const hashArray = Array.from( new Uint8Array( hashBuffer ) );                       // Converst the ArrayBuffer type back into a UTF-8 byte array, and then converts into a generic JS array.
    return hashArray.map( byte => byte.toString( 16 ).padStart( 2, "0" ) ).join( "" );  // The generic array is then converted into hex and returned.
}

/**
 * Format some money.
 * @param {Number} amount 
 * @param {String} currency 
 * @returns {String}
 */
export function utl_fmt_mny( amount, currency = null ) {
    if ( ! currency ) {
        currency = configuration.defaultCurrency;
    }

    // See JIRA-287 for more info on this.
    const formatter = new Intl.NumberFormat( "en-GB", {
        style: "currency",
        currency: currency
    } );

    return formatter.format( amount );
}

/**
 * Shakes a HTML element for a given amount of time, in milliseconds.
 * @param {HTMLElement} element 
 * @param {Number} time 
 */
export function utl_shake_element( element, time = 250 ) {
    utl_hash_string_to_hex( "jRumbleInitialised" ).then( function ( key ) { 
        if ( ! $( element ).data( key ) ) { 
            $( element ).jrumble();
            $( element ).attr( `data-${ key }`, true );
        }
    
        $( element ).startRumble();
    
        setTimeout( function () {
            $( element ).stopRumble();
        }, time );
    });

    // console.warn( "Rumbling is disabled." );
}

/**
 * Sets valid state on validator element.
 * @param {HTMLElement} element 
 */
export function utl_set_validator_valid( element ) {
    // Set the actual main element to have the `valid` class.
    $( element )
        .addClass( "valid" )
        .removeClass( "invalid" );

    // Set it's parent `validator` to have the `valid` class.
    $( element )
        .parents( ".validator" )
        .addClass( "valid" )
        .removeClass( "invalid" );
}

/**
 * Sets invalid state on validator element.
 * @param {HTMLElement} element 
 */
export function utl_set_validator_invalid( element ) {
    // Set the actual main element to have the `invalid` class.
    $( element )
        .addClass( "invalid" )
        .removeClass( "valid" );

    // Set it's parent `validator` to have the `invalid` class.
    $( element )
        .parents( ".validator" )
        .addClass( "invalid" )
        .removeClass( "valid" );

    // Snazzy shake.
    utl_shake_element( element );
}

/**
 * Sets non-validated state on validator element.
 * @param {HTMLElement} element 
 */
export function utl_set_validator_non_validated( element ) {
    // Remove the `valid` and `invalid` class from the main element...
    $( element )
        .removeClass( "invalid" )
        .removeClass( "valid" );

    // ..and the parent `validator` element.
    $( element )
        .parents( ".validator" )
        .removeClass( "invalid" )
        .removeClass( "valid" );
}

/**
 * Patch the error message that displays for no apparent reason when typing in the phone number.
 */
export function utl_patch_number_input() {
    // Set an interval that will self-destruct once the phone utils are loaded and we can overwrite the offending function.
    const intervalId = setInterval( function () { 
        if ( ! window[ "intlTelInputUtils" ] ) {
            return;
        }

        window[ "intlTelInputUtils" ][ "formatNumberAsYouType" ] = function ( val, arg0_unk ) {
            return val;
        }
        clearInterval( intervalId );
    }, 25 );
}

/**
 * Bit confusing in naming, given the above... This prevents a user from ever typing "+", "-", or "e" into a number field. These are mathematical but useless when it comes to getting user input.
 * Also, how are we going to charge a negative number, or an irrational number. lol.
 * 
 * NOTE: Run this after DOM ready event has executed.
 */
export function utl_patch_number_input_field() {
    $( `input[type="number"]` ).on( "keydown", 
        /** @param {KeyboardEvent} event */
        function ( event ) {
            if ( [ "-", "+", "e" ].includes( event.key ) ){
                event.preventDefault()
            }
        } );
}

/**
 */
export function utl_slugify( str ) {
    if ( ! str ){
        return str;
    }
    return str.normalize( "NFKD" )        // Normalize to NFKD Unicode form
        .toLowerCase()              // Convert to lowercase
        .trim()                     // Trim whitespace from both sides
        .replace( /\s+/g, "-" )     // Replace spaces with -
        .replace( /[^\w\-]+/g, "" ) // Remove all non-word chars
        .replace( /\-\-+/g, "-" );  // Replace multiple - with single -;
}

/**
 * Makes an input only allow typing slugs which conform to our standard.
 * @param {HTMLInputElement} input 
 */
export function utl_make_slug_input( input ) {
    $( input ).on( "focusout", function ( event ) {
        setTimeout( ( function () {
            this.value = utl_slugify( this.value );

            // Future me: figure out a better way to implement this. For now, this is acceptable since we 
            // have a fairly contained system  - however a util function SHOULD NOT be calling a dynamic
            // variable that may not exist.
            if ( Alpine.$data( this ) ) {
                try {
                    Alpine.$data( this ).appeal.custom_url = this.value;
                } catch ( _ ) {
                    // Empty try-catch since this could just break, I cannot be bothered checking if the fields exist on the "slug" input's Alpine.
                }
            }
        } ).bind( this ), 100 );
    } );
}

/**
 * Decodes HTML entities.
 */
export default function utl_html_decode_entities( str ) {
    var element         = document.createElement( "div" );
    str                 = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str                 = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML   = str;
    str                 = element.textContent;
    element.textContent = '';
    element.remove();

    return str;
}