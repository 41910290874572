import Alpine from "alpinejs";
import Validator from "../classes/Validator";
import Application from "../main/Application";
import $ from "jquery";
import APIStatic from "../classes/APIStatic";

/**
 * Controller to interact with the backend to sign up users to the mailing list. Requires a turnstile capture to work.
 */
export default class MailingListController {
    /**
     * Our application instance.
     * @var {Application}
     */
    app = null;

    /**
     * The interval for waiting on CF to load turnstile.
     */
    interval = null;

    /**
     * Construct.
     * @param {Application} app 
     */
    constructor( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called on page ready.
     */
    onReady() {
        // Wait for Turnstile to be ready.
        this.interval = setInterval( this.checkTurnstileReady.bind( this ), 500 );
    }

    /**
     * Runs on an interval to check if turnstile has been initialised. This should be converted into some sort of hook event from CFTurnstilService.
     */
    checkTurnstileReady() {
        if ( ! this.app.services.CFTurnstileService.turnstileReady ) {
            return;
        }

        clearInterval( this.interval );
        this.onTurnstileReady();
    }

    /**
     * Called once the above function determines that turnstile is ready to go.
     */
    onTurnstileReady() { 
        this.input().prop( "disabled", false );

        this.submitBtn().on( "click", $.debounce( 150, this.onSubmit.bind( this ) ) );
        this.input().on( {
            "focusout": this.onFocusoutEmail.bind( this )
        } );
    }

    /**
     * Called on submit.
     */
    onSubmit( e ) {
        if ( e ) {
            e.preventDefault();
        }

        Alpine.$data( document.querySelector( "#mail-submit" ) ).state = "loading";
        this.input().prop( "disabled", true );
        this.submitBtn().prop( "disabled", true );

        const value = this.input().val();
        if ( ! Validator.isEmailValid( value, false, false ) ) {
            this.showError( "Please enter a valid email address." );
            Alpine.$data( document.querySelector( "#mail-submit" ) ).state = null;
            this.input().prop( "disabled", false );
            this.submitBtn().prop( "disabled", false );
            return;
        } else {
            this.showError();
        }

        this.app.services.CFTurnstileService.getToken( this.onRetreivedCFToken.bind( this ) );
    }

    /**
     * Called when we've submitted and the CF token has been returned.
     */
    onRetreivedCFToken( token ) {
        const email = this.input().val();

        ( ( async function ( token, email ) {
            const response = await $.ajax( {
                url:        APIStatic.endpoint( "mail-subscribe" ),
                method:     "POST",
                async:      true,
                data: { 
                    _token:         APIStatic.csrf(),
                    _tsToken:       token,
                    email_address:  email
                }
            } );

            if ( response.status ) {
                this.showSuccess( true );
            } else {
                this.showError( response.error );
            }
        } ).bind( this ) )( token, email );
    }

    /**
     * Called on focusout event of the email field.
     */
    onFocusoutEmail( e ) {
        if ( e ) {
            e.preventDefault();
        }

        this.showError();
    }

    /**
     * Displays an error message to the user.
     */
    showError( message = false ) {
        if ( ! message ) {
            this.error().addClass( "hidden" );
        } else {
            this.error().removeClass( "hidden" );
            this.error().text( message );
        }
    }

    /**
     * Displays, or hides, the success message.
     */
    showSuccess( show ) {
        if ( show ) {
            this.success().removeClass( "hidden" );
        } else {
            this.success().addClass( "hidden" );
        }
    }

    /**
     * Gets the button used for submitting.
     */
    submitBtn() { 
        return $( "#mail-submit" );
    }

    /**
     * Gets the input used for the mail sign up.
     */
    input() { 
        return $( "#mail-sign-up" );
    }

    /**
     * Gets the element used for the mail error messages.
     */
    error() {
        return $( "#mail-error" );
    }

    /**
     * Gets the element used for the mail success message.
     */
    success() {
        return $( "#mail-success" );
    }
}