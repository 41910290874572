"use strict";

import $ from "jquery";
import { Reeller, ScrollerPlugin } from "reeller";
import gsap from "gsap";

Reeller.registerGSAP( gsap );
Reeller.use( ScrollerPlugin );

export default class ReelerController {
    /**
     * Collection of reeller instances.
     * @var {Object<string,Reeller>} reellers
     */
    reellers = {};

    /**
     * Class constructor.
     */
    constructor()  {
        $( this.onReady.bind( this ) );    
    }

    /**
     * Called on document ready.
     */
    onReady() {
        document
            .querySelectorAll( ".reeller-container" )
            .forEach( this.initialiseReeller.bind( this ) );
        
        console.info( "Initialised ReellerController" );
    }

    /**
     * Initialises a reeller instance and adds it to the mappings.
     * @param {HTMLElement} element 
     */
    initialiseReeller( element ) {
        // Instanciate a new reeller object.
        const reeller = new Reeller( {
            container:      element,
            wrapper:        ".reeller-wrapper",
            itemSelector:   ".reeller-item",
            speed:          35,
            initialSeek:    6,
            plugins: {
                scroller: {
                    speed: 1,
                    multiplier: 0.1,
                    threshold: 1,
                    reversed: false,
                },
            },
        } );

        // Start Reeler once initialised
        reeller.resume();

        // Add to collection / map.
        this.reellers[ element.id ] = reeller;
    }
}