"use strict";

import App from "../main/Application";
import $ from "jquery";
import StoreCustomerService from "../services/StoreCustomerService";
import DonationData from "../alpine/DonationData";
import StripeOneOffService from "../services/StripeOneOffService";
import StripeSubscriptionService from "../services/StripeSubscriptionService";
import CFTurnstileService from "../services/CFTurnstileService";
import Feedback from "../classes/Feedback";
import toastr from "toastr";

/**
 * Controls the Stripe frontend operations.
 */
export default class StripeController {
    /**
     * @type {App}
     */
    app = null;

    /**
     * @type {stripe.Stripe}
     */
    stripe = null;

     /**
     * The Stripe Elements object.
     * 
     * @var {stripe.Elements}
     */
    elements = null;
    
    /**
     * The class constructor
     * 
     * @param {App} app
     * @param {{public_key:string|null}} stripeConfig
     */
    constructor ( app, stripeConfig ) {
        this.app    = app;
        this.stripe = new Stripe( stripeConfig.public_key );

        $( this.onReady.bind( this ) );
    }

    /**
     * Called when DOM is ready.
     */
    onReady() {
        $( "#pm-card-payment" ).on( "click", this.onOpenStripeCardForm.bind( this ) );
        $( "#confirm-stripe-payment" ).off( "click" );

        console.info( "Initialised StripeController" );
    }

    /**
     * Called when the user opens the Stripe card form.
     */
    onOpenStripeCardForm() {
        /** @type {CFTurnstileService} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService )  {
            console.error( "CFTurnstileService is not initialised." ); 
            return;
        }

        cfTurnstileService.getToken( this.onStep2Validated.bind( this ) );
    }

    onCloseStripeCardForm() {
        this.paymentElement = null;
        this.elements = null;
    }

    /**
     * @param {string} turnstileToken
     */
    onStep2Validated( turnstileToken ) {
        /** @type {StoreCustomerService} */
        const storeCustomerService = this.app.services.StoreCustomerService;
        if ( ! storeCustomerService ) {
            console.error( "StoreCustomerService is not initialised." );
            return;
        }

        /** @type {DonationData} */
        const donationDetails = this.app.getDonationProxy();
        if ( ! donationDetails ) {
            console.error( "getDonationProxy returned nothing." );
            return;
        }

        // Copy the donor's information into the storeCustomerService instances `storeCustomer` method, which will generate or overwrite the customer info.
        storeCustomerService.storeCustomer(
            donationDetails.donor.title,
            donationDetails.donor.first_name,
            donationDetails.donor.last_name,
            donationDetails.donor.email,
            donationDetails.donor.phone,
            donationDetails.address.line_1,
            donationDetails.address.line_2,
            donationDetails.address.city,
            donationDetails.address.state,
            donationDetails.address.postal_code,
            donationDetails.address.country_code
        ).then( this.onStoredCustomer.bind( this ) );
    }

    /**
     * Called when the customer details are stored successfully.
     * @param {{donor:string|null,address:string|null,status:string}|{error:string}} response
     */
    onStoredCustomer( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ( ! response.status ) || ( ! response.address ) || ( ! response.donor ) ) {
            Feedback.error( response );
            return;
        }

        this.app.customer.reference         = response.donor;
        this.app.customer.address_reference = response.address;

        /** @type {CFTurnstileService|null} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService ) {
            console.error( "CFTurnstileService is not initialised." );
            return;
        }

        // Turnstile re-executed as the previous token was consumed by the user storage.
        cfTurnstileService.getToken( this.initialiseStripePaymentForm.bind( this ) );
    }

    /**
     * Initialises the stripe payment form.
     * 
     * @param {string} token The turnstil token.
     */
    initialiseStripePaymentForm( token ) {
        /** @type {DonationData|null} */
        const donationDetails       = this.app.getDonationProxy();
        if ( ! donationDetails ) {
            console.error( "getDonationProxy returned nothing." );
            return;
        }

        /** @type {StripeOneOffService|null} */
        const stripeOneOffService = this.app.services.StripeOneOffService;
        if ( ! stripeOneOffService ) {
            console.error( "StripeOneOffService has not been initialised." );
            return;
        }

        /** @type {StripeSubscriptionService|null} */
        const stripeSubscriptionService = this.app.services.StripeSubscriptionService;
        if ( ! stripeSubscriptionService ) {
            console.error( "StripeSubscriptionService has not been initialised." );
            return;
        }

        if ( donationDetails.donation.frequency === "one-time" ) {
            stripeOneOffService
                .createPaymentIntentFromDonationData( donationDetails.donation, token )
                .then( this.onGeneratedPaymentIntent.bind( this ) )
                .catch( this.onFailedGeneratedPaymentIntent.bind( this ) );
        } else {
            stripeSubscriptionService
                .createSubscription( donationDetails.export(), token )
                .then( this.onGeneratedStripeSubscription.bind( this ) )
                .catch( this.onFailedGeneratedSubscription.bind( this ) );
        }
    }

    /**
     * Called once we have a valid payment intent.
     * 
     * @param {{}} response 
     */
    onGeneratedPaymentIntent( response ) { 
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ! response?.payment_intent?.client_secret ) {
            console.error( "No client secret generated." );
            return;
        }

        this.elements = this.stripe.elements( { 
            clientSecret: response.payment_intent.client_secret
        } );

        this.paymentElement = this.elements.create( "payment" );
        this.paymentElement.mount( "#stripe-card-form" );
        this.paymentElement.on( "ready", this.onPaymentElementReady.bind( this ) );

        $( "#confirm-stripe-payment" ).on( "click", this.onClickConfirm.bind( this ) );
    }

    /**
     * Called once we have a response from the stripe subscription creation.
     * 
     * @param {{}} response 
     */
    onGeneratedStripeSubscription( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }
        
        if ( ! response?.client_secret ) {
            return;
        }

        console.log( "onGeneratedStripeSubscription", response );

        this.elements = this.stripe.elements( { 
            clientSecret: response.client_secret
        } );

        this.paymentElement = this.elements.create( "payment" );
        this.paymentElement.mount( "#stripe-card-form" );
        this.paymentElement.on( "ready", this.onPaymentElementReady.bind( this ) );

        $( "#confirm-stripe-payment" ).on( "click", this.onClickConfirm.bind( this ) );
    }

    /**
     * Called on failure to generate a payment intent through Stripe.
     * 
     * @param {*} error
     */
    onFailedGeneratedPaymentIntent( error ) { 
        console.error( error );
    }

    /**
     * Called on failure to generate a subscription through Stripe.
     * 
     * @param {*} error
     */
    onFailedGeneratedSubscription( error ) { 
        console.error( error );
    }

    /**
     * Called when the Stripe payment element self-reports as ready.
     */
    onPaymentElementReady() {
        $( "#stripe-card-form-wrapper" ).removeClass( "hidden" );
        $( window ).trigger( "stripe-element-ready" );
    }

    /**
     * Called once the complete payment button is clicked.
     */
    async onClickConfirm() {
        let endpoint;

        if ( this.app.getDonationProxy().donation.frequency === "one-time" ) {
            endpoint = "update-payment-intent";
        } else {
            endpoint = "update-stripe-subscription";
        }

        if ( ! endpoint ) {
            console.error( "No viable endpoint for stripe payment or subscription confirmation." );
            throw new "No viable endpoint for Stripe payment or subscription confirmation";
        }

        const result = await this.stripe.confirmPayment( {
            confirmParams: {
                return_url: `${window.location.origin}/front-api/v1/${endpoint}`
            },
            
            elements:       this.elements,
        } )
            .then( this.onConfirmedPayment.bind( this ) )
            .catch( this.onConfirmFailed.bind( this ) )
        
        return result;
    }

    /**
     * Called on card payment confirmation.
     * 
     * @param {{}} results 
     */
    onConfirmedPayment( results ) {
        if ( results.error ) {
            this.app.donatePage.previousPage();
            this.app.getDonationProxy().is_donation_processing = false;
            toastr.error( results.error.message );
        }
    }

    onConfirmFailed( error ) {
        throw error;
    }
}