"use strict";

import $ from "jquery";

/** @extends {AlpineComponent} */
const DonationCompletedData = {

    currentPage: "donation-completed",

    /**
     * History list of `currentPage`.
     */
    passedPages: [
        ""
    ],

    /**
     * Figures out which classes to apply to the stepper, based on the current step.
     */
    stepperClassesFor( step ) {
        return {
            "active":   true,
            "done":     true
        };
    },

    /**
     * @return {void}
     */
    init: function () {

    },

    /**
     * @return {void}
     */
    destroy: function () {
        
    }
}

export {
    DonationCompletedData as default
};