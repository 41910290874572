"use strict";

import Feedback from "../classes/Feedback";
import { utl_hash_string_to_hex, utl_test_api } from "../util";

/**
 * List things to expose to Window / globalThis here.
 * Keeps the code clean so we can remove them later.
 */

window.utl_hash_string_to_hex = utl_hash_string_to_hex;
window.Feedback = Feedback;
window.utl_test_api = utl_test_api;

export default function window_expose( key, value = null ) {
    window[ key ] = value;
}