"use strict";

import $                                from "jquery";
import App                              from "../main/Application";
import DonationData                     from "../alpine/DonationData";
import GoCardlessOneOffService          from "../services/GoCardlessOneOffService";
import GoCardlessSubscriptionService    from "../services/GoCardlessSubscriptionService";
import StoreCustomerService             from "../services/StoreCustomerService";
import CFTurnstileService               from "../services/CFTurnstileService";
import Feedback from "../classes/Feedback";
import toastr from "toastr";

/**
 * Controls GoCardless functionality on the frontend.
 */
export default class GoCardlessController {
    /** 
     * The parent application instance.
     * @type {App}
     */
    app = null;

    /**
     * Class constructor.
     * 
     * @param {App} app 
     */
    constructor ( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called when the DOM is ready.
     */
    onReady() {
        // $( "#pm-bank-transfer" ).on( "click", this.onSelectBankTransfer.bind( this ) );
        $( "#pm-bank-transfer--continue-wrap" ).addClass( "hidden" );
        $( "#pm-bank-transfer--complete-payment" ).off( "click" );

        console.info( "Initialised GoCardlessController" );
    }

    /**
     * Called when the user selects Bank Transfer as their payment method.
     */
    onSelectBankTransfer() {
        console.info( "GoCardless payment selected." );

        /** @type {CFTurnstileService} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService )  {
            console.error( "CFTurnstileService is not initialised." ); 
            return;
        }

        // Generate TS token to be used for the customer data store.
        cfTurnstileService.getToken( this.onStep2Validated.bind( this ) );
    }

    /**
     * @param {string} turnstileToken 
     */
    onStep2Validated( turnstileToken ) {
        /** @type {StoreCustomerService|null} */
        const storeCustomerService = this.app.services.StoreCustomerService;
        if ( ! storeCustomerService ) {
            console.error( "StoreCustomerService has not been initialised." );
            return;
        }

        /** @type {DonationData} */
        const donationDetails = this.app.getDonationProxy();
        if ( ! donationDetails ) {
            console.error( "getDonationProxy returned nothing." );
            return;
        }

        // Copy the donor's information into the storeCustomerService instances `storeCustomer` method, which will generate or overwrite the customer info.
        storeCustomerService.storeCustomer(
            donationDetails.donor.title,
            donationDetails.donor.first_name,
            donationDetails.donor.last_name,
            donationDetails.donor.email,
            donationDetails.donor.phone,
            donationDetails.address.line_1,
            donationDetails.address.line_2,
            donationDetails.address.city,
            donationDetails.address.state,
            donationDetails.address.postal_code,
            donationDetails.address.country_code
        ).then( this.onStoredCustomer.bind( this ) );
    }

    /**
     * Called when the customer details are stored successfully.
     * @param {{donor:string|null,address:string|null,status:string}|{error:string}} response
     */
    onStoredCustomer( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ( ! response.status ) || ( ! response.address ) || ( ! response.donor ) ) {
            Feedback.log( response );
            return;
        }

        this.app.customer.reference         = response.donor;
        this.app.customer.address_reference = response.address;

        /** @type {CFTurnstileService|null} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService ) {
            console.error( "CFTurnstileService is not initialised." );
            return;
        }

        // Turnstile re-executed as the previous token was consumed by the user storage.
        cfTurnstileService.getToken( this.initialiseBillingRequest.bind( this ) );
    }

    /**
     * Initialises the stripe payment form.
     * 
     * @param {string} token The turnstile token.
     */
    initialiseBillingRequest( token ) {
        /** @type {DonationData|null} */
        const donationDetails = this.app.getDonationProxy();
        if ( ! donationDetails ) {
            console.error( "getDonationProxy returned nothing." );
            return;
        }

        /** @type {GoCardlessOneOffService|null} */
        const goCardlessOneOffService = this.app.services.GoCardlessOneOffService;
        if ( ! goCardlessOneOffService ) {
            console.error( "GoCardlessOneOffService has not been initialised." );
            return;
        }

        /** @type {GoCardlessSubscriptionService|null} */
        const goCardlessSubscriptionService = this.app.services.GoCardlessSubscriptionService;
        if ( ! goCardlessSubscriptionService ) {
            console.error( "GoCardlessSubscriptionService has not been initialised." );
            return;
        }

        if ( donationDetails.donation.frequency === "one-time" ) {
            goCardlessOneOffService
                .createBillingRequestFromDonationData( donationDetails.export(), token )
                .then( this.onCreateBillingRequest.bind( this ) )
                .catch( this.onFailedCreateBillingRequest.bind( this ) )
        } else {
            goCardlessSubscriptionService
                .createSubscription( donationDetails.export(), token )
                .then( this.onGeneratedGCSubscription.bind( this ) )
                .catch( this.onFailedGCSubscription.bind( this ) )
        }
    }

    /**
     * Called when the billing request creation request responds successfully.
     * 
     * @param {*} response 
     */
    onCreateBillingRequest( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ! response.redirect_url ) {
            return;
        }

        window.location.href = response.redirect_url;
    }

    /**
     * Called when the GoCardless subscription creation request responds successfully.
     * 
     * @param {*} response 
     */
    onGeneratedGCSubscription( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }
        
        if ( ! response.redirect_url ) {
            return;
        }

        window.location.href = response.redirect_url;
    }

    /**
     * Called on failure to store the customer details.
     * 
     * @param {{}} error error data.
     */
    onFailedStoreCustomer( error ) {
        console.error( error );
    }

    /**
     * Called when there's a known error response while creating the GoCardless billing request.
     * 
     * @param {*} error 
     */
    onFailedCreateBillingRequest( error ) {
        console.error( error );
    }

    /**
     * Called when there's a known error response while creating the GoCardless subscription.
     * 
     * @param {*} error 
     */
    onFailedGCSubscription( error ) {
        console.error( error );
    }
}