"use strict";

export function equalHeights(elm) {
    let maxHeight = 0;
    const items = typeof elm == "string" ? document.querySelectorAll(elm) : elm;

    items.forEach((el) => {
        maxHeight = Math.max(el.clientHeight, maxHeight);
    });

    items.forEach((el) => {
        el.style.height = `${maxHeight}px`;
    });
}

const Universal = { equalHeights };

export default Universal;
