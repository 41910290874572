"use strict";

import $ from "jquery";
import Application from "../main/Application";

/**
 * Controller for the cart popout component.
 */
export default class ModalController {
    /**
     * Instance of main application.
     * @type {Application|null}
     */
    app = null;

    /**
     * Class constructor.
     */
    constructor ( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called on document ready.
     */
    onReady() { 
        if ( $( ".toggle-modal" ).length === 0 ) {
            console.warn( "ModalController: No modals found on page to initialise." );
            return;
        }

        // Bind all of the toggle buttons for the modals.
        $( ".toggle-modal" ).on( "click", this.toggleModal.bind( this ) );

        console.info( "ModalController: Initialised" );
    }

    /**
     * Toggles a modals visibility.
     * @param {InputEvent} event
     */
    toggleModal( event ) {
        // The button.
        const button = event.currentTarget;
        if ( ! button ) {
            console.error( `ModalController.toggleModal: Event has no valid target.` );
            return;
        }

        // Get the selector name
        const elmSelector = button.dataset?.target;
        if ( ! elmSelector ) {
            console.error( `ModalController.toggleModal: #${ button.id } does not have a data-target attribute set.` );
            return;
        }

        // Targeting the actual element from the selector
        const elmTarget = document.querySelector( elmSelector );
        if ( ! elmTarget ) {
            console.error( `ModalController.toggleModal: #${ elmSelector } does not exist.` );
            return;
        }

        // Get the close elements
        const elmCloses = elmTarget.querySelectorAll( ".close-modal" );
        if ( ! elmCloses ) {
            console.error( `ModalController.toggleModal: #${ elmTarget.id } is missing a child with class .close-modal` );
            return;
        }

        // Open the modal
        if ( ! elmTarget.classList.contains( "open" ) ) {
            elmTarget.classList.add( "open" );
        }

        // Loop the close element
        elmCloses.forEach( elmClose => {
            // Close the modal
            elmClose.addEventListener( "click", () => {
                ModalController.#closeModal( elmTarget );
            } )
        } );
    }

    /**
     * Closes a modal.
     * @param {HTMLElement} modal 
     */
    static #closeModal( modal ) {
        if ( modal.classList.contains( "open" ) ) {
            modal.classList.remove( "open" )
        }
    }
}