"use strict";

import App                          from "../main/Application";
import $                            from "jquery";
import PayPalOneOffService          from "../services/PayPalOneOffService";
import PayPalSubscriptionService    from "../services/PayPalSubscriptionService";
import CFTurnstileService           from "../services/CFTurnstileService";
import StoreCustomerService         from "../services/StoreCustomerService";
import DonationData                 from "../alpine/DonationData";
import Feedback from "../classes/Feedback";
import toastr from "toastr";

export default class PayPalController {
    /** 
     * The parent application instance.
     * @type {App}
     */
    app = null;

    /**
     * Class constructor.
     * 
     * @param {App} app 
     */
    constructor ( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called when the DOM is ready.
     */
    onReady() { 
        // $( "#pm-paypal" ).on( "click", this.onSelectPayPalPayment.bind( this ) );
        $( "#pm-paypal--continue-wrap" ).addClass( "hidden" );
        $( "#pm-paypal--complete-payment" ).off( "click" );

        console.info( "Initialised PayPalController" );
    }

    /**
     * Called when the user selects to pay via PayPal.
     */
    onSelectPayPalPayment() {
        console.info( "PayPal payment selected." );

        /** @type {CFTurnstileService} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService )  {
            console.error( "CFTurnstileService is not initialised." ); 
            return;
        }

        cfTurnstileService.getToken( this.onStep2Validated.bind( this ) );
    }

    /**
     * @param {string} turnstileToken
     */
    onStep2Validated( turnstileToken ) {
        /** @type {StoreCustomerService|null} */
        const storeCustomerService  = this.app.services.StoreCustomerService;

        /** @type {DonationData} */
        const donationDetails       = this.app.getDonationProxy();

        // Copy the donor's information into the storeCustomerService instances `storeCustomer` method, which will generate or overwrite the customer info.
        storeCustomerService.storeCustomer(
            donationDetails.donor.title,
            donationDetails.donor.first_name,
            donationDetails.donor.last_name,
            donationDetails.donor.email,
            donationDetails.donor.phone,
            donationDetails.address.line_1,
            donationDetails.address.line_2,
            donationDetails.address.city,
            donationDetails.address.state,
            donationDetails.address.postal_code,
            donationDetails.address.country_code
        ).then( this.onStoredCustomer.bind( this ) );
    }

    /**
     * Called when the customer details are stored successfully.
     * @param {{donor:string|null,address:string|null,status:string}|{error:string}} response
     */
    onStoredCustomer( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ( ! response.status ) || ( ! response.address ) || ( ! response.donor ) ) {
            Feedback.log( "onStoredCustomer: ", response );
            return;
        }

        this.app.customer.reference         = response.donor;
        this.app.customer.address_reference = response.address;

        /** @type {CFTurnstileService|null} */
        const cfTurnstileService = this.app.services.CFTurnstileService;
        if ( ! cfTurnstileService ) {
            Feedback.error( "CFTurnstileService is not initialised." );
            return;
        }

        // Turnstile re-executed as the previous token was consumed by the user storage.
        cfTurnstileService.getToken( this.initialisePayPalOrder.bind( this ) );
    }

    initialisePayPalOrder( token ) {
        /** @type {DonationData|null} */
        const donationDetails       = this.app.getDonationProxy();
        if ( ! donationDetails ) {
            Feedback.error( "getDonationProxy returned nothing." );
            return;
        }

        /** @type {PayPalOneOffService|null} */
        const payPalOneOffService = this.app.services.PayPalOneOffService;
        if ( ! payPalOneOffService ) {
            Feedback.error( "PayPalOneOffService has not been initialised." );
            return;
        }

        /** @type {PayPalSubscriptionService|null} */
        const payPalSubscriptionService = this.app.services.PayPalSubscriptionService;
        if ( ! payPalSubscriptionService ) {
            Feedback.error( "PayPalSubscriptionService has not been initialised." );
            return;
        }

        if ( donationDetails.donation.frequency === "one-time" ) {
            payPalOneOffService
                .createOrderFromDonationData( donationDetails.export(), token )
                .then( this.onCreatePayPalOrderResponse.bind( this ) )
                .catch ( this.onFailedPayPalOrderResponse.bind( this ) );
        } else {
            payPalSubscriptionService
                .createSubscription( donationDetails.export(), token )
                .then( this.onGeneratedPlanPayPal.bind( this ) )
                .catch ( this.onFailedPayPalPlanResponse.bind( this ) );
        }
    }

    /**
     * Called when the PayPal product object is created, or fails to create on the server without an error code.
     * @param {*} response 
     */
    onCreatePayPalOrderResponse( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }

        if ( ! response.status ) {
            console.error( "onCreatePayPalOrderResponse: ", response.error );
            return;
        }

        window.location.href = response.redirect_url;
    }

    /**
     * 
     * @param {Object} response 
     * @returns 
     */
    onGeneratedPlanPayPal( response ) {
        if ( response.error ) {
            toastr.error( response.error );
            return;
        }
        
        if ( ! response.status ) {
            console.error( "onGeneratedPlanPayPal: ", response.error );
            return;
        }

        window.location.href = response.redirect_url;
    }

    /**
     * Called on failure to generate PayPal payment / order.
     * @param {*} error 
     */
    onFailedPayPalOrderResponse( error ) {
        console.error( "onFailedPayPalOrderResponse: ", error );
    }

    onFailedPayPalPlanResponse( error ) {
        console.error( "onFailedPayPalPlanResponse: ", error );
    }
}