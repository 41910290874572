"use strict";

import Quill from "quill";
import Application  from "../main/Application";
import $            from "jquery";

export default class TextEditorController {

    /**
     * @type {Application}
     */
    app = null;

    /**
     * Mapping of currently loaded editors.
     * @type {Object<string,FroalaEditor>} Map of ID => Editor instance.
     */
    loadedEditors = {};

    /**
     * Options for the editor
     */
    TOOLBAR_BUTTONS = [
        [ 'bold', 'italic', 'underline', 'strike' ],        // toggled buttons
        [ 'blockquote' ],
        [ 'link', 'image', 'video' ],
      
        [{ 'header': 1 }, { 'header': 2 } ],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      
        [{ 'size': [ 'small', false, 'large', 'huge' ] }],  // custom dropdown
        [{ 'header': [ 1, 2, 3, 4, 5, 6, false ] }],
      
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
      
        ['clean']                                         // remove formatting button
      ];

    /**
     * The class constructor.
     * @param {Application} app 
     */
    constructor ( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called on application ready.
     */
    onReady() {
        this.initialiseEditors();
        
        const nLoaded = Object.keys( this.loadedEditors ).length;
        console.log( `Initialised ${ nLoaded }` ); 
        console.info( "Initialised TextEditors." );
    }

    /**
     * Initialises the editor instances.
     */
    initialiseEditors() {
        const editors = document.querySelectorAll( ".js-text-editor" );

        if ( ! editors.length ) {
            return;
        }

        editors.forEach( this.initialiseSingleEditor.bind( this ) );
    }

    /**
     * Initialises a new Froala rich editor element.
     * @param {HTMLElement} element 
     */
    initialiseSingleEditor( element ) {
        const editor = new Quill( element, {
            modules: {
              toolbar: this.TOOLBAR_BUTTONS,
            },
            placeholder: '\n\n',
            theme: 'snow',
          });

        this.loadedEditors[ element.id ] = editor;
    }

    /**
     * Gets an editor.
     * @param {String} id 
     * @returns {Quill}
     */
    getEditor( id ) {
        return this.loadedEditors[ id ];
    }
}