import NetworkTransport from "../classes/NetworkTransport";
import Application from "../main/Application";

/**
 * Service used for checking if an appeal is already taken.
 */
export default class AppealSlugCheckService {
    /**
     * @var {Application} appeal
     * @type {Application}
     */
    app = null;

    /**
     * @param {Application} app
     */
    constructor ( app ) { 
        this.app = app;
    }

    /**
     * Determines if a given slug has been taken by an appeal yet.
     * @return {Promise<Boolean>}
     */
    async checkIfSlugIsTaken( slug ) { 
        const response = await 
            ( new NetworkTransport( {
                slug: slug
            } ) )
                .sendTo( "check-slug" );

        // Check the response.
        if ( ! response?.status ) {
            console.error( "No response / bad response while checking slug availability.", response );
            return;
        }

        // Check the reference code is filled - if this is 
        return response.taken === true;
    }
}