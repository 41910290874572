"use strict";

import { utl_get_app } from "../util";
import $ from "jquery";

/**
 * Defines a set of very very specific validation rules used across the donation flow.
 */
export default class Validator {
    /**
     * Determines if a given value expected to be text is valid, based on given parameters.
     * 
     * @param {any} value 
     * @param {boolean} canBeBlank 
     * @param {boolean} canBeNull 
     * @returns {boolean}
     */
    static isTextValid( value, canBeBlank = false, canBeNull = false ) {
        if ( canBeBlank && canBeNull ) {
            return true;
        }

        if ( ( ! canBeNull ) && ( ! value ) ) {
            return false;
        }

        if ( ( ! canBeBlank ) && ( ! value.length ) ) {
            return false;
        }

        return true;
    }

    /**
     * Determines if a given expected numeric value is valid, based on given parameters.
     * 
     * @param {any} value Expected as number. Could be any.
     * @param {boolean} canBeBlank 
     * @param {boolean} canBeZero 
     * @param {number|null} min
     * @param {number|null} max
     * @returns {boolean}
     */
    static isNumberValid( value, canBeBlank = false, canBeZero = false, min = null, max = null ) {
        if ( ( ! canBeBlank ) && ( ! value ) ) {
            return false;
        }

        if ( ( ! canBeZero ) && Number( value ) === 0 ) {
            return false;
        }

        if ( min !== null ) {
            if ( Number( value ) < min ) {
                return false;
            }
        }

        if ( max !== null ) {
            if ( Number( value ) > max ) {
                return false;
            }
        }

        return true;
    }

    /**
     * Determines if a given expected email-formatted string value is valid, based on given parameters.
     * 
     * @param {any} value 
     * @param {boolean} canBeBlank 
     * @param {boolean} canBeNull 
     * @return {boolean}
     */
    static isEmailValid( value, canBeBlank = false, canBeNull = false ) {
        if ( ! this.isTextValid( value, canBeBlank, canBeNull ) ){
            return false;
        }

        return this.#validateEmail( value );
    }

    /**
     * Determines ig a given expected date value is valid, based on given parameters.
     * 
     * @param {any} value 
     * @param {boolean} canBeBlank 
     * @param {boolean} canBeNull 
     */
    static isDateValid( value, canBeBlank = false, canBeNull = false, min = null, max = null ) {
        // Basic check that there actually is a value.
        if ( ! this.isTextValid( value, canBeBlank, canBeNull ) ) {
            return false;
        }

        // If we have both min and max values, then check the value is in the range.
        if ( min && max ) {
            return ( new Date( value ) ) > ( new Date( min ) ) && 
                ( new Date( value ) ) < ( new Date( max ) );
        }

        // Just a min, make sure the value is above the minimum.
        if ( min ) {
            return ( new Date( value ) ) > ( new Date( min ) );
        }

        // Just a max, make sure the value is lower than the maximum.
        if ( max ) {
            return ( new Date( value ) ) < ( new Date( max ) );
        }

        // Otherwise all cases are fine, since we do normal text validation prior to getting to this point.
        return true;
    }

    /**
     * Determines if a given telephone input is valid.
     * @param {HTMLInputElement} input 
     * @returns {boolean}
     */
    static isPhoneValid( input ) {
        if ( ! utl_get_app() ){ 
            console.error( "Validator.isPhoneValid: `utl_get_app` can only be used when an application instance is available!" );
            return true;
        }

        // Grab the tel input, if we can.
        const tel = utl_get_app()
            ?.phoneController
            ?.get( input.id );

        // If the tel input was grabbed, then try extract the selected country code.
        const country = tel
            ?.defaultCountry
            ?.toUpperCase() ?? "GB"; // .. defaulting to UK - is this bad?
            
        const utl = window.intlTelInputUtils;
        return utl ? utl.isValidNumber( input.value, country ) : Validator.isTextValid( input.value, false, false );
    }

    /**
     * Validates a HTML input element (or a JQuery set containing one item), using the methods defined in this class.
     * 
     * @param {JQuery<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>|HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} input     The input element or JQ set.
     * @param {boolean} requiresVisibility                          Do we require to have the element be visible to be validated?
     */
    static validateHTMLInput( input, requiresVisibility = false, validatesWhenDisabled = true ) {
        if ( ! input ) {
            console.warn( "Empty input passed into `validateHTMLInput`" );
            return false;
        }

        // If the input's class is not the one we're after, then extract. We assume this means JQuery set.
        if ( ( ! this.isValidateable( input ) ) && ( ! ( input instanceof HTMLElement ) ) ) {
            input = input.get( 0 );

            // Double check the input type.
            if ( ! this.isValidateable( input ) ) {
                console.warn( "`validateHTMLInput` expects HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement" );
                return false;
            }
        }

        // Automatically "Valid" if the input element is not visible, if `requiresVisibility` is set.
        if ( requiresVisibility ) {
            if ( ! $( input ).is( ":visible" ) ) {
                return true;
            }
        }

        // If the field is not required then any value is valid... for now.
        if ( ( ! input.required ) || ( input.dataset?.required && ( input.dataset?.required !== "true" ) ) ) {
            return true;
        }

        // If `valdiatesWhenDisabled` is set, then a disabled element will
        if ( validatesWhenDisabled && input.disabled ) {
            return true;
        }

        // Figure out what type of input this is.
        let type = input.type ?? null;
        if ( ! type ) {
            if ( input instanceof HTMLSelectElement ) {
                type = "select-one";
            } else {
                type = "textarea";
            }
        }

        // Based on the type of the input, and whether it requires validation, we validate with corresponding methods.
        /** https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#type */
        switch ( input.type ) {
            case "text":
                return this.isTextValid( 
                    input.value, 
                    input.required, 
                    false 
                );
            case "email":
                return this.isEmailValid( 
                    input.value, 
                    input.required, 
                    false 
                );
            case "tel":
                return this.isPhoneValid( input );
            case "select":
            case "select-one":
                return ( input.value?.length ?? 0 ) > 0;
            case "textarea":
                return this.isTextValid( 
                    input.value, 
                    input.required, 
                    false 
                );
            case "number":
                return this.isNumberValid( 
                    input.value, 
                    ! input.required, 
                    false, 
                    input.min, 
                    input.max 
                );
            
            // Cases that cannot be validated but must be considered.
            case "button":
            case "checkbox":
            case "color":
            case "date":
            case "datetime-local":
                return this.isDateValid( 
                    input.value, 
                    input.required, 
                    false, 
                    input.dataset?.min ?? null,
                    input.dataset?.max ?? null 
                );
            case "file":
            case "hidden":
            case "image":
            case "month":
            case "radio":
            case "range":
            case "reset":
            case "search":
            case "submit":
            case "time":
            case "url":
            case "week":
            default: 
                return true;
        }
    }

    /**
     * Determines if a given value is an email. Potentially doesn't cover all cases, but as noted in the comments of that
     * post - it's pretty much impossible to fully validate an email address given the nature of usernames in differing
     * systems... https://stackoverflow.com/a/46181
     * 
     * @param {string|any} email 
     * @returns {boolean}
     */
    static #validateEmail( email ) {
        return !! ( String( email )
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) );
    }

    /**
     * Is the given element validateable by our flow scripts?
     * 
     * @param {HTMLElement} element 
     * @returns 
     */
    static isValidateable( element ) {
        return ( element instanceof HTMLInputElement ) || ( element instanceof HTMLTextAreaElement ) ||( element instanceof HTMLSelectElement );
    }
}