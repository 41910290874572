"use strict";

import NetworkTransport from "../classes/NetworkTransport";

/**
 * Handles Stripe subscription payments.
 */
export default class StripeSubscriptionService {
    /**
     * The current stripe JS instance.
     * 
     * @var {stripe.Stripe}
     */
    stripe = null;

    /**
     * Parent application instance.
     * 
     * @var {App} app
     */
    app = null;

    /**
     * Service constructor.
     * 
     * @param {App}             app     The parent application instance.
     * @param {stripe.Stripe}   stripe 
     */
    constructor( app, stripe ) {
        this.app        = app;
        this.stripe     = stripe;
    }

    /**
     * Gets the current stripe instance.
     * 
     * @returns {stripe.Stripe}
     */
    getStripe() {
        return this.stripe;
    }

    /**
     * Create a payment intent.
     * 
     * @returns {Promise<{payment_intent:{id:string,client_secret:null}|null}|null>}
     */
    async createSubscription( donationData, tsToken ) {
        return new NetworkTransport( {
            donor:          this.app.customer.reference,
            address:        this.app.customer.address_reference,
            amount:         donationData.amount,
            adminPercent:   donationData.has_admin_fee ? donationData.amount_admin_fee : 0,
            appealId:       donationData.appeal_id,
            isAnonymous:    donationData.is_anonymous,
            appealMessage:  donationData.appeal_message,
            donation_type:  donationData.donation_type,
            is_gift_aid:    donationData.is_gift_aid,
            currency:       donationData.currency,
            reoccurrence:   donationData.frequency_reoccurring, 
            termType:       "open", // If a user changes this manually then so be it. we just won't collect their money for as long.
            termLength:     0, // Zero means infinity in this context.
            customFields:   this.app.services.CustomFieldsService.collectCustomFieldValues(),
            projectId:      donationData.sub_project ?? donationData.project,

            isCompany:      donationData.is_company,
            companyName:    donationData.company_name,

            _tsToken:       tsToken
        } ).sendTo( "stripe/subscription" );
    }
}