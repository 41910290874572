"use strict";

import $ from "jquery";

export default class APIStatic {
    /**
     * The current API namespace.
     * 
     * @var {String} API_NAMESPACE
     */
    static API_NAMESPACE = "front-api";

    /**
     * The current API version.
     * 
     * @var {String} API_VERSION
     */
    static API_VERSION = "v1";

    /**
     * Gets the Stripe configuration from our API endpoint for the Stripe initialisation.
     * 
     * @return {Promise<{ public_key: string|null }>}
     */
    static async stripeConfig( reCAPTCHAToken = null ) { 
        return $.ajax( {
            url:        APIStatic.endpoint( "stripe/config" ),
            method:     "GET",
            dataType:   "JSON",
            data: { 
                _token:         APIStatic.csrf(),
                reCAPTCHAToken: reCAPTCHAToken
            }
        } );
    }

    /**
     * Get a full API endpoint string.
     * 
     * @param {String} endpoint 
     * @returns {String}
     */
    static endpoint( endpoint ) {
        return `${ window.location.origin }/${ APIStatic.API_NAMESPACE }/${ APIStatic.API_VERSION }/${ endpoint }`;
    }

    /**
     * Get the current CSRF token.
     * 
     * @returns {String}
     */
    static csrf() { 
        return $( `meta[name="csrf-token"]` )[ 0 ].content;
    }
}