"use strict";

import { 
    utl_set_validator_invalid, 
    utl_set_validator_non_validated, 
    utl_set_validator_valid }   from "../util";
import $, { error }                    from "jquery";
import Validator            from "../classes/Validator";
import App                  from "../main/Application";
import DonationData         from "../alpine/DonationData";
import toastr               from "toastr";
import Donor from "../classes/Donor";

/**
 * Page controller.
 */
export default class FlowController {
    /**
     * The flow container
     * 
     * @var {JQueryStatic<HTMLDivElement>}
     */
    $container = null;

    /** 
     * @type {App}
     */
    app = null;

    containerId = null;

    paymentConfig = {};

    /**
     * Constructor.
     * 
     * @param {string} containerId
     */
    constructor ( app, containerId ) { 
        this.app = app;
        this.containerId = containerId;

        $( this.onReady.bind( this ) );
    }

    /**
     * Called on DOM ready event.
     */
    onReady() {
        const interval = setInterval( ( function () { 
            try {
                this.app.getDonationProxy().flowController = this;
            } catch ( _ ) {
                return;
            } 

            this.$container = $( ( this.containerId.startsWith( "#" ) ) ? this.containerId : `#${ this.containerId }` );

            this.setupFormValidation();
            this.setupDonorData();

            console.info( "Initialised DonationFlowController" );

            clearInterval( interval );

            if ( this.paymentConfig[ "one-off" ] ) {
                /** @todo */
                const oneOffConfig = this.paymentConfig[ "one-off" ];

                if ( ! oneOffConfig.stripe_enabled ) {
                    $( "#pm--stripe" ).addClass( "!hidden" );
                }

                if ( ! oneOffConfig.paypal_enabled ) {   
                    $( "#pm--paypal" ).addClass( "!hidden" );
                }

                if ( ! oneOffConfig.gocardless_enabled ) {
                    $( "#pm--gocardless" ).addClass( "!hidden" );
                }
            }

            if ( this.paymentConfig[ "subscriptions" ] ) {
                /** @todo */
                const subscriptionConfig = this.paymentConfig[ "subscriptions" ];

                if ( ! subscriptionConfig.stripe_enabled ) {
                    $( "#pm--stripe" ).addClass( "!hidden" );
                }
                
                if ( ! subscriptionConfig.paypal_enabled ) {
                    $( "#pm--paypal" ).addClass( "!hidden" );
                }
                
                if ( ! subscriptionConfig.gocardless_enabled ) {
                    $( "#pm--gocardless" ).addClass( "!hidden" );
                }
            }
            
            const urlParams = new URLSearchParams( window.location.search );
            const redirectReason = urlParams.get( "redirect_reason" );
            const errorCode = urlParams.get( "error_code" );

            if ( redirectReason === "paypal-payment-cancelled" && errorCode && errorCode !== "null" ) {
                toastr.warning( "Your PayPal payment has been cancelled.\n\nIf you did not intend this, please contact support citing code: " + errorCode );
                urlParams.set( "redirect_reason", null );
                urlParams.set( "error_code", null );

                const currentUrl = new URL( window.location );
                currentUrl.searchParams.set( "redirect_reason", null );
                currentUrl.searchParams.set( "error_code", null );

                window.history.pushState({ path: currentUrl.toString() }, '', currentUrl.toString());
            }

            if ( redirectReason === "paypal-subscription-cancelled" && errorCode && errorCode !== "null" ) {
                toastr.warning( "Your PayPal subscription setup has been cancelled.\n\nIf you did not intend this, please contact support citing code: " + errorCode );
                urlParams.set( "redirect_reason", null );
                urlParams.set( "error_code", null );

                const currentUrl = new URL( window.location );
                currentUrl.searchParams.set( "redirect_reason", null );
                currentUrl.searchParams.set( "error_code", null );

                window.history.pushState({ path: currentUrl.toString() }, '', currentUrl.toString());
            }
        } ).bind( this ), 250 );
    }

    /**
     * Called by alpine, this moves the page forward if validation passes.
     */
    nextPage() {
        /** @type {DonationData} */
        const donationProxy = this.app.getDonationProxy();
        
        switch ( donationProxy.currentPage ) {
        case "donation-details":
            if ( this.validatePage( "donation-details" ) ) {
                donationProxy.currentPage = "personal-details";
                donationProxy.passedPages.push( "donation-details" );
            } else {
                // Additional rules for failure to validate donation details.
                console.warn( `Full validation failed on page: ${ donationProxy.currentPage }` );
            }
            break;
        case "personal-details":
            if ( this.validatePage( "personal-details" ) ) {
                donationProxy.currentPage = donationProxy.isGiftAidEligible() ? "gift-aid" : "payment-methods";
                donationProxy.passedPages.push( "personal-details" );

                // mark the gift aid step manually if GA isn't eligible
                if ( !donationProxy.isGiftAidEligible()) donationProxy.passedPages.push( "gift-aid" );
            } else {
                // Additional rules for failure to validate personal details.
                console.warn( `Full validation failed on page: ${ donationProxy.currentPage }` );

                // Manual address is only shown when the address field is fully validated.
                if ( ! donationProxy.address.isValid() ) {
                    donationProxy.address.manual = true;
                }
            }
            break;
        case "gift-aid":
            if ( this.validatePage( "payment-methods" ) ) {
                donationProxy.currentPage = "payment-methods";
                donationProxy.passedPages.push( "gift-aid" );
            } else {
                // Additional rules for failure to validate payment methods.
                console.warn( `Full validation failed on page: ${ donationProxy.currentPage }` );
            }
            break;
        case "payment-methods":
            if ( this.validatePage( "taxpayer-details" ) ) {
                donationProxy.currentPage = "taxpayer-details";
                donationProxy.passedPages.push( "payment-methods" );
            } else {
                // Additional rules for failure to validate taxpayer details.
                console.warn( `Full validation failed on page: ${ donationProxy.currentPage }` );
            }
            break;
        }
    }

    /**
     * Called by alpine, this moves the page back, if it can.
     */
    previousPage() {
        /** @type {DonationData} */
        const donationProxy = this.app.getDonationProxy();
                
        switch ( donationProxy.currentPage ) {
        case "taxpayer-details":
            donationProxy.currentPage = "payment-methods";
            break;
        case "payment-methods":
            donationProxy.currentPage = donationProxy.isGiftAidEligible() ? "gift-aid" : "personal-details";

            /** @todo */
            this.cancelCurrentPayment();
            break;
        case "gift-aid":
            donationProxy.currentPage = "personal-details";
            break;
        case "personal-details":
            donationProxy.currentPage = "donation-details";
            break;
        }
    }
    
    cancelCurrentPayment() {
        /** @type {DonationData} */
        const donationProxy = this.app.getDonationProxy();

        donationProxy.destroyPaymentMethod();
    }

    /**
     * Sets up the `onfocusout` event on all of our text/email/tel/number inputs.
     */
    setupFormValidation() {
        this.$container.find( `input, textarea, select` ).each( ( function ( _, element ) {
            if ( element.type === "hidden" ) {
                return;
            }

            this.setupInputValidation( element );
        } ).bind( this ) );
    }

    /**
     * Setup the stored donor data.
     */
    setupDonorData() {
        /** @var {Donor|null} @type {Donor} */
        const currentDonor = this.app?.currentDonor;

        if ( ! currentDonor ) {
            return;
        }

        // lol.
        setTimeout( ( function () { 
            const donationProxy = this.app.getDonationProxy();
            
            if ( this.app?.currentDonor ) {
                donationProxy.donor.email       = this.app.currentDonor.email ?? "";
                donationProxy.donor.title       = this.app.currentDonor.title ?? "";
                donationProxy.donor.first_name  = this.app.currentDonor.first_name ?? "";
                donationProxy.donor.last_name   = this.app.currentDonor.last_name ?? "";
                donationProxy.donor.phone       = this.app.currentDonor.phone ?? "";
    
                if ( currentDonor.authenticated ) {
                    donationProxy.donor.email_locked = true;                
                }
            }
        } ).bind( this ), 250 );
    }

    /**
     * Sets up validation for a specific form input element.
     * @param {HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement} element 
     */
    setupInputValidation( element ) {
        $( element ).on( { 
            "focusout": this.onFocusOutInput.bind( this, element ),
            "input":    this.onInputInput.bind( this, element ),
            "change":   this.onFocusOutInput.bind( this, element )
        } );
    }

    /**
     * Validates the current flow page.
     * @return {boolean}
     */
    validateCurrentPage() {
        /** @type {DonationData} */
        const donationData = this.app.getDonationProxy();
        return this.validatePage( donationData.currentPage );
    }

    /**
     * Validates a single form page, for checking if we can proceed.
     * @param {string} pageId
     * @return {boolean}
     */
    validatePage( pageId ) {
        // Find the given container.
        const $pageContainer = $( `.flow-page[data-page-id="${ pageId }"]` );

        if ( ! $pageContainer.length ) {
            throw `Invalid flow page passed: ${ pageId }`;
        }

        // Collect all outputted validation states.
        var validationStates = [];
        $pageContainer.find( `input, textarea, select` ).each( ( function ( _, element ) {
            if ( element.type === "hidden" ) {
                return;
            }

            const validateWhenHidden = !!$( element ).data( "validate-when-hidden" );
            const validationState = Validator.validateHTMLInput( element, !validateWhenHidden );
            
            this.setValidationStatus( element, validationState, element.required );
            
            validationStates.push( validationState );
        } ).bind( this ) );

        // If there's any "bad" validation states then the page isn't validated.
        return !( validationStates.includes( false ) );
    }

    /**
     * Called on `input` event of any input.
     */
    onInputInput( element ) {
        this.setValidationStatus( element, null );   
    }

    /**
     * Called on focusout event of any input.
     */
    onFocusOutInput( element ) {
        if ( ! element ) {
            return;
        }

        // Try native to get value.
        let value = element.value;

        // Try Jquery to get value.
        if ( ! value ) {
            value = $( element ).val(); // If null here, that is fine. It'll probably just becomes non-validated.
        }

        if ( element?.value?.length === 0 ) {
            this.setValidationStatus( element, null );
        } else if ( Validator.validateHTMLInput( element ) ) {
            this.setValidationStatus( element, true );
        } else {
            this.setValidationStatus( element, false );
        }
    }

    /**
     * Sets the validation status of a given element.
     * 
     * @param {HTMLInputElement|JQuery<HTMLInputElement>} element   The element to apply the validation styles etc to.
     * @param {boolean|null} status                                 Standard three-way validation status. Null = No validation. False = Invalid. True = Valid.
     */
    setValidationStatus( element, status, nullIsInvalid = false ) {
        if ( ! element ) {
            return;
        }

        // Pull out the actual element if a JQuery set is passed.
        if ( ( ! Validator.isValidateable( element ) ) && ( ! ( element instanceof HTMLElement ) ) ) {
            element = element?.get( 0 );
        }

        // If we didn't get a result then throw a message.
        if ( ! element ) {
            console.error( `Attempt to set validation status on invalid element: #${ element.id }` );
            return;
        }

        // True result
        if ( status === true ) {
            utl_set_validator_valid( element );
        }

        // Bad result, or no result which is marked as bad.
        if ( status === false || ( status === null && nullIsInvalid ) ) {
            utl_set_validator_invalid( element );
            console.warn( `Flow field validation failed: #${ element.id }` );
        } else {
            // No result, which means empty field
            if ( status === null ) {
                utl_set_validator_non_validated( element );
            }
        }
    }

    /**
     * Get the page container instance.
     * 
     * @return {JQuery<HTMLDivElement>}
     */
    getContainer() {
        return this.$container;
    }
}