"use strict";

// Import both common forms of the jQuery static object.
import $                    from "jquery";
import jQuery               from "jquery";
import "jquery-match-height";

// Application imports.
import Application          from "./main/Application";
import initialiseJRumble    from "./vendor/jackrugile/jrumble/jquery.jrumble.1.3";
import initDebounce         from "./vendor/verge/jquery.debounce";

// Configure jQuery.
initialiseJRumble( $ );
initialiseJRumble( jQuery );
initDebounce( $ );

// Intialise the frontend application.
new Application();

jQuery(document).ready(function($) {
    const isQurbaniProject = () => {
        let isSelected;
        
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('project');

        if($("#main_project_selection option[value='proj_2gbHekEndTCW6AEHI5tQRSelUoF']").is(":selected")) {
            isSelected = true;
        } else {
            isSelected = false;
        }
        return isSelected;
    }

    const hideZakat = () => {
        $("#donation-type-wrapper").hide();
        $("#donation-type").val("sadaqah");
    }

    const showZakat = () => {
        $("#donation-type-wrapper").show();
        $("#donation-type").val("");
    }

    const toggleZakat = () => {
        if(isQurbaniProject()) {
            hideZakat();
        } else {
            showZakat();
        };
    }
    
    if("#donation-app") {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('project');
        
        if(myParam === "proj_2gbHekEndTCW6AEHI5tQRSelUoF") {
            hideZakat();
        }

        $("#main_project_selection").on('change', function() {
            toggleZakat();
        })
    }
    



    $(".toggleMenu").click(function() {
        
        $("#mobile-menu").toggle();
    })

    $(".mobile-nav li.has_children > div").on("click", function () {
        event.preventDefault();
        $(".mobile-nav li > ul").addClass("hidden");
        $(this).siblings("ul").toggleClass("hidden");
    });



    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
    
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
})