"use strict";

import $ from "jquery";

export default class CustomFieldsService {
    /**
     * Parent application instance.
     * 
     * @var {App} app
     */
    app = null;

    /**
     * Constructor
     * 
     * @param {App} app 
     */
    constructor( app ) {
        this.app = app;
    }
    
    /**
     * 
     * @return { {
    *  donation_details: Array<{
    *      data: {},
    *      type: string
    *  }>|null,
    *  payment_details: Array<{
    *      data: {},
    *      type: string
    *   }>|null,
    *  user_details: Array<{
    *      data: {},
    *      type: string
    *   }>|null
    * }|null }
    */
   getKnownCustomFields() {
       return window[ "_customFields" ];
   }

   /**
    * Scans all known custom fields and maps out the values given, for storing against the payment data.
    * 
    * @todo !
    * @return {*}
    */
   collectCustomFieldValues() { 
        return []
        const donation_details  = this.getKnownCustomFields()?.donation_details ?? [];
        const payment_details   = this.getKnownCustomFields()?.payment_details  ?? [];
        const user_details      = this.getKnownCustomFields()?.user_details     ?? [];  

        var fields = [];

        // Scan for the donation details.
        donation_details.forEach( ( function ( field ) {
            const key = field?.data[ "Field Key" ] ?? null;

            if ( ! key ) {
                return;
            }

            fields.push( {
                section:    "donation_details",
                key:        key,
                value:      this.customFieldValue( key, field.type )
            } )
        } ).bind( this ) );

        // Scan for the payment details.
        payment_details.forEach( ( function ( field ) {
            const key = field?.data[ "Field Key" ] ?? null;

            if ( ! key ) {
                return;
            }

            fields.push( {
                section:    "payment_details",
                key:        key,
                value:      this.customFieldValue( key, field.type )
            } )
        } ).bind( this ) );

        // Scan for the user details.
        user_details.forEach( ( function ( field ) {
            const key = field?.data[ "Field Key" ] ?? null;

            if ( ! key ) {
                return;
            }

            fields.push( {
                section:    "user_details",
                key:        key,
                value:      this.customFieldValue( key, field.type )
            } )
        } ).bind( this ) );

        return fields;
   }

   /**
    * Finds the value of a custom field.
    * 
    * @param {String} id 
    * @param {String} type
    * @return {Any}
    */
   customFieldValue( id, type ) {
       if ( ! $( `[data-custom-field="${ id }"]` ).length ) {
           return;
       }

       switch ( type.toLowerCase() ) {
       case "checkbox":
       case "zakat":
       case "gift aid":
           return $( `#${ id }` ).prop( "checked" ) ? "1" : "0";
       default: 
           return $( `#${ id }` ).val();
       }
   }
}