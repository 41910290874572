"use strict";

import { 
    utl_make_slug_input,
    utl_set_validator_invalid, 
    utl_set_validator_non_validated, 
    utl_set_validator_valid }   from "../util";
import $                    from "jquery";
import Validator            from "../classes/Validator";
import App                  from "../main/Application";
import DonationData         from "../alpine/DonationData";
import AppealCreateData     from "../alpine/AppealCreateData";
import Alpine               from "alpinejs";
import toastr from "toastr";

/**
 * Page controller.
 */
export default class AppealCreateFlowController {
    /**
     * The flow container
     * 
     * @var {JQueryStatic<HTMLDivElement>}
     */
    $container = null;

    /** 
     * @type {App}
     */
    app = null;

    /**
     * Constructor.
     * 
     * @param {string} containerId
     */
    constructor ( app, containerId ) { 
        this.$container = $( ( containerId.startsWith( "#" ) ) ? containerId : `#${ containerId }` );

        if ( this.$container.length === 0 ) {
            this.$container = null;
            throw "Invalid container passed into PageController";
        }

        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Real return type is Proxy<AppealCreateData>.
     * @returns {AppealCreateData}
     */
    getDataProxy() {
        return Alpine.$data( document.querySelector( "#appeal-create-app" ) );
    }

    /**
     * Called on DOM ready event.
     */
    onReady() {
        const interval = setInterval( ( function () { 
            try {
                // Link the page data to this controller.
                this.getDataProxy().flowController = this;

                console.log( "Waiting for Alpine..." );
            } catch ( _ ) {
                return;
            }

            this.setupFormValidation();
            this.setupDonorData();
            this.setupCustomisedInputs();

            console.info( "Initialised AppealCreateFlowController" );

            clearInterval( interval );
        } ).bind( this ), 250 );
    }

    /**
     * Executes some validation hooks.
     */
    static runValidationHooks( validationHooks, hookName ) {
        const hooks = validationHooks[ hookName ];
        if ( ( !hooks ) || ( hooks.length === 0 ) ){ 
            return true;
        }
        
        var responses = [];
        hooks.forEach( ( function ( method ) {
            responses.push( method() );
        } ) );

        // Valid if the list of responses DOES NOT include false.
        return ! responses.includes( false );
    }

    /**
     * Called by alpine, this moves the page forward if validation passes.
     * @param {Object<string,Array>} validationHooks A list 
     */
    nextPage( validationHooks = {} ) {
        /** @type {AppealCreateData} */
        const creationProxy = this.getDataProxy();
        
        // Logic should be a lookup table here, with a key of "From" and value of "To".
        switch ( creationProxy.currentPage ) {
        case "basic-details":
            if ( this.validatePage( creationProxy.currentPage ) && AppealCreateFlowController.runValidationHooks( validationHooks, creationProxy.currentPage ) ) {
                creationProxy.currentPage = "goals";
            } else {
                // Additional rules for failure to validate donation details.
                console.warn( `Full validation failed on page: ${ creationProxy.currentPage }` );
            }
            break;
        case "goals":
            if ( this.validatePage( creationProxy.currentPage ) && AppealCreateFlowController.runValidationHooks( validationHooks, creationProxy.currentPage ) ) {
                creationProxy.currentPage = "story";
            } else {
                // Additional rules for failure to validate personal details.
                console.warn( `Full validation failed on page: ${ creationProxy.currentPage }` );
            }
            break;
        case "story":
            if ( this.validatePage( creationProxy.currentPage ) && AppealCreateFlowController.runValidationHooks( validationHooks, creationProxy.currentPage ) ) {
                creationProxy.currentPage = "giving-levels";
            } else {
                // Additional rules for failure to validate payment methods.
                console.warn( `Full validation failed on page: ${ creationProxy.currentPage }` );
            }
            break;
        case "giving-levels":
            if ( this.validatePage( creationProxy.currentPage ) && AppealCreateFlowController.runValidationHooks( validationHooks, creationProxy.currentPage ) ) {
                creationProxy.currentPage = "completed";
            } else {
                // Additional rules for failure to validate payment methods.
                console.warn( `Full validation failed on page: ${ creationProxy.currentPage }` );
            }
            break;
        }
    }

    /**
     * Called by alpine, this moves the page back, if it can.
     */
    previousPage() {
        /** @type {AppealCreateData} */
        const creationProxy = this.getDataProxy();
                
        switch ( creationProxy.currentPage ) {
        case "completed":
            creationProxy.currentPage = "giving-levels";
            console.warn( "Moving back to `giving-levels`." );
            break;
        case "giving-levels":
            creationProxy.currentPage = "story";
            console.warn( "Moving back to `story`." );
            break;
        case "story":
            creationProxy.currentPage = "goals";
            console.warn( "Moving back to `goals`." );
            break;
        case "goals":
            creationProxy.currentPage = "basic-details";
            console.warn( "Moving back to `basic-details`." );
            break;
        }
    }

    /**
     * Called on request for final upload.
     */
    finalUpload() { 
        /** @type {AppealCreateData} */
        const creationProxy = this.getDataProxy();

        this
            .app
            .services
            .AppealCreateService
            .createAppeal( creationProxy.appealData(), this.onAppealGenerated.bind( this ) );
    }

    /**
     * Called when an appeal is generated.
     */
    onAppealGenerated( response ) {   
        this.getDataProxy().finalise( response );
    }

    /**
     * Sets up the `onfocusout` event on all of our text/email/tel/number inputs.
     */
    setupFormValidation() {
        this.$container.find( `input, textarea, select` ).each( ( function ( _, element ) {
            if ( element.type === "hidden" ) {
                return;
            }

            this.setupInputValidation( element );
        } ).bind( this ) );
    }

    /**
     * Setup the stored donor data.
     */
    setupDonorData() {
        if ( ! window[ "donorData" ] ) {
            return;
        }

        // The inputted donor data fields.
        const donorData = window[ "donorData" ];

        /** @type {AppealCreateData} The current donation data instance.*/
        const appealProxy = this.getDataProxy()

        // The keys present in the inputted donor data fields.
        const keys = Object.keys( donorData );

        // Iterate on the keys.
        for ( let idx in keys )  {
            const key = keys[ idx ];

            // Update the donor information.
            appealProxy.donor[ key ] = donorData[ key ];

            // If the email is being set automatically, then don't allow the user to change it.
            // User would have to log out to change this.
            if ( key === "email" && donorData[ key ] && donorData[ "logged_in" ] ) {
                appealProxy.donor.email_locked = true;                
            }
        }
    }

    /**
     * Sets up modifiers for the input fields.
     */
    setupCustomisedInputs() {
        // Slug inputs.
        $( `input[is-slug][type="text"]` ).each( ( index, element ) => { 
            utl_make_slug_input( element );
        } );

        // Number inputs with a step need to actually use the value by rounding.
        $( `input[type="number"][step], input[money]` ).on( "focusout", function ( event ) {
            const step = parseFloat( $( this ).attr( "step" ) );
            const value = parseFloat( this.value );
            const roundedValue = Math.round( value / step ) * step;

            if ( ! isNaN( roundedValue ) && ! isNaN( value ) && ! isNaN( step ) ) {
                $( this ).val( roundedValue.toFixed( 2 ) );
            }
        } );
    }

    /**
     * Sets up validation for a specific form input element.
     * @param {HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement} element 
     */
    setupInputValidation( element ) {
        $( element ).on( { 
            "focusout": this.onFocusOutInput.bind( this, element ),
            "input":    this.onInputInput.bind( this, element ),
            "change":   this.onFocusOutInput.bind( this, element )
        } );
    }

    /**
     * Validates the current flow page.
     * @return {boolean}
     */
    validateCurrentPage() {
        /** @type {DonationData} */
        const donationData = this.app.getDonationProxy();
        return this.validatePage( donationData.currentPage );
    }

    /**
     * Validates a single form page, for checking if we can proceed.
     * @param {string} pageId
     * @return {boolean}
     */
    validatePage( pageId ) {
        // Find the given container.
        const $pageContainer = $( `.flow-page[data-page-id="${ pageId }"]` );

        if ( ! $pageContainer.length ) {
            throw `Invalid flow page passed: ${ pageId }`;
        }

        // Collect all outputted validation states.
        var validationStates = [];
        $pageContainer.find( `input, textarea, select` ).each( ( function ( _, element ) {
            if ( element.type === "hidden" ) {
                return;
            }

            const validateWhenHidden = !!$( element ).data( "validate-when-hidden" );
            const validationState = Validator.validateHTMLInput( element, !validateWhenHidden );
            
            this.setValidationStatus( element, validationState, element.required );
            
            validationStates.push( validationState );
        } ).bind( this ) );

        // If there's any "bad" validation states then the page isn't validated.
        return !( validationStates.includes( false ) );
    }

    /**
     * Called on `input` event of any input.
     */
    onInputInput( element ) {
        this.setValidationStatus( element, null );   
    }

    /**
     * Called on focusout event of any input.
     */
    onFocusOutInput( element ) {
        if ( ! element ) {
            return;
        }

        // Try native to get value.
        let value = element.value;

        // Try Jquery to get value.
        if ( ! value ) {
            value = $( element ).val(); // If null here, that is fine. It'll probably just becomes non-validated.
        }

        if ( element?.value?.length === 0 ) {
            this.setValidationStatus( element, null );
        } else if ( Validator.validateHTMLInput( element ) ) {
            this.setValidationStatus( element, true );
        } else {
            this.setValidationStatus( element, false );
        }
    }

    /**
     * Sets the validation status of a given element.
     * 
     * @param {HTMLInputElement|JQuery<HTMLInputElement>} element   The element to apply the validation styles etc to.
     * @param {boolean|null} status                                 Standard three-way validation status. Null = No validation. False = Invalid. True = Valid.
     */
    setValidationStatus( element, status, nullIsInvalid = false ) {
        if ( ! element ) {
            return;
        }

        // Pull out the actual element if a JQuery set is passed.
        if ( ( ! Validator.isValidateable( element ) ) && ( ! ( element instanceof HTMLElement ) ) ) {
            element = element?.get( 0 );
        }

        // If we didn't get a result then throw a message.
        if ( ! element ) {
            console.error( `Attempt to set validation status on invalid element: #${ element.id }` );
            return;
        }

        // True result
        if ( status === true ) {
            utl_set_validator_valid( element );
        }

        // Bad result, or no result which is marked as bad.
        if ( status === false || ( status === null && nullIsInvalid ) ) {
            utl_set_validator_invalid( element );
            console.warn( `Flow field validation failed: #${ element.id }` );
        } else {
            // No result, which means empty field
            if ( status === null ) {
                utl_set_validator_non_validated( element );
            }
        }
    }

    /**
     * Get the page container instance.
     * 
     * @return {JQuery<HTMLDivElement>}
     */
    getContainer() {
        return this.$container;
    }
}