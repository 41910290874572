"use strict";

export default class Donor {
    /**
     * The donor's email address.
     * @var {string|null}
     */
    email = null;

    /**
     * The donor's title.
     * @var {string|null}
     */
    title = null;

    /**
     * The donor's first name.
     * @var {string|null}
     */
    first_name = null;

    /**
     * The donor's last name.
     * @var {string|null}
     */
    last_name = null;
    
    /**
     * The donor's phone number
     * @var {string|null}
     */
    phone = null;

    /**
     * Are they logged in?
     * @var {string|null}
     */
    authenticated = false;

    /**
     * Class constructor
     * @param {string|null} email 
     * @param {string|null} title 
     * @param {string|null} first_name 
     * @param {string|null} last_name 
     * @param {string|null} phone 
     * @param {boolean|null} authenticated
     */
    constructor ( 
        email       = null,
        title       = null,
        first_name  = null, 
        last_name   = null, 
        phone       = null,
        authenticated = null
    ) {
        this.email          = email;
        this.title          = title;
        this.first_name     = first_name;
        this.last_name      = last_name;
        this.phone          = phone;
        this.authenticated  = authenticated ?? false;
    }

    /**
     * Construct a new Donor instance from any object by copying the properties.
     * @param {Object} object 
     */
    static constructFrom( object ) {
        const donor = new Donor;
        const keys = Object.keys( object );
        const possibleKeys = [
            "email", "title", "first_name", "last_name", "phone", "authenticated"
        ];

        // Copy object in.
        for ( let idx in possibleKeys ) {
            const possibleKey = possibleKeys[ idx ];
            if ( keys.includes( possibleKey ) ) {
                donor[ possibleKey ] = object[ possibleKey ];
            }
        }

        return donor;
    }
}