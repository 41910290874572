"use strict";

import $ from "jquery";
import Feedback from "../classes/Feedback";
import DonationData from "../alpine/DonationData";

/**
 * Google maps autocomplete handler.
 */
export default class GoogleAutocompleteService {
    /**
     * Has the google maps API initialised?
     * 
     * @var {Booleans}
     */
    hasInitialised = false;

    /** 
     * Callback for when the API has fully loaded.
     * 
     * @var {function|null}
     */
    onFullyInitialised = null;

    /**
     * Parent app instance.
     */
    app = null;

    /**
     * Setup DOM to accept Google Maps places API.
     */
    constructor ( app ) {
        this.app = app;

        window[ "initGoogleMaps" ] = this.initGoogleMaps.bind( this );
    }

    /**
     * This method is called by the loaded Google API.
     */
    initGoogleMaps() {
        this.hasInitialised = true;

        this.onInitialiseGoogleAPI();
    }

    /**
     * Creates a new Google maps autocomplete instance.
     * 
     * @param {HTMLInputElement} input 
     */
    createAutocomplete( input )  {
        /** @type {google.maps.places.AutocompleteOptions} */
        const options = {
            bounds:                     null,
            componentRestrictions:      null,   // This would be used to restrict the donor country.
            fields:                     null,   // Truncates the output data.
            strictBounds:               null
        };

        /** @type {google.maps.places.Autocomplete} */
        const constructAutocomplete = this.places().Autocomplete;
        const autocomplete = new constructAutocomplete( input, options );

        autocomplete.setFields( this.getAvailableFields() );

        return autocomplete;
    }

    /**
     * "By default, when a user selects a place, autocomplete returns all 
     * of the available data fields for the selected place, and you will be
     * billed accordingly. Use Autocomplete.setFields() to specify which 
     * place data fields to return. Read more about the PlaceResult object, 
     * including a list of place data fields that you can request. To avoid
     * paying for data that you don't need, be sure to use 
     * Autocomplete.setFields() to specify only the place data that you will use."
     *  - https://developers.google.com/maps/documentation/javascript/place-autocomplete#get-place-information
     */
    getAvailableFields() {
        return [
            "street_number",
            "route",
            "postal_town",
            "administrative_area_level_2",
            "postal_code",
            "country",
            "business_status",
            "name"
        ];
    }

    /**
     * Get the global places instance.
     * 
     * @return {google.maps.places}
     */
    places() {
        /** @type {google} */
        const google = window[ "google" ];

        if ( ! google ) {
            throw "Google JS API not loaded.";
        }

        /** @type {google.maps} */
        const maps = google[ "maps" ];

        if ( ! maps ) {
            throw "Google Maps JS API not loaded.";
        }

        /** @type {google.maps.places} */
        const places = maps[ "places" ];

        if ( ! places ) {
            throw "Google Maps Places API not loaded;";
        }

        return places;
    }

    /**
     * Called on initialisation of the google API.
     */
    onInitialiseGoogleAPI() {
        const $addressField = $( "#address-search" );
        
        if ( $addressField.length !== 1 ) {
            throw "Google autocomplete field not found.";
        } else {
            Feedback.notice( "Google autocomplete initialised." );
        }

        this.autocomplete = this.createAutocomplete( $addressField.get( 0 ) );
        this.autocomplete.addListener( "place_changed", this.onAutocompletePlaceChanged.bind( this ) );
    }

    /**
     * Called when a new place is selected in the autocomplete.
     */
    onAutocompletePlaceChanged() {
        /**
         * Grab new place info. 
         * @type {google.maps.places.Place} 
         */
        const place         = this.autocomplete.getPlace();

        /**
         * Grab proxy to final donation information. 
         * @type {DonationData} 
         */
        const donation      = this.app.getDonationProxy();

        // Decode the place into it's manageable components.
        const decoded       = this.decodePlaceComponents( place );

        const newKeys       = Object.keys( decoded );           // The new keys generated from the place.
        const existingKeys  = Object.keys( donation.address );  // The allowed keys.

        donation.address.clear();

        // Copy over any new keys we have.
        for ( let idx in newKeys ) {
            const key = newKeys[ idx ];

            if ( existingKeys.includes( "line_1" ) ) {
                donation.address[ key ] = decoded[ key ]; 
            }
        }

        // Determine if it's a business.
        if ( ( place.business_status === "OPERATIONAL" ) && place.name ) {
            this.handleAutocompleteBusiness( place );
        }
    }

    /** 
     * Called when the autocomplete address is that of a business.
     * 
     * @param {google.maps.places.Place} place
     */
    handleAutocompleteBusiness( place ) {
        /** @type {DonationData} */
        const donation = this.app.getDonationProxy();
        donation.company_name = place.name;
    }

    /**
     * 
     * @param {google.maps.places.Place} placeData
     * @return {{
     *  line_1:         string,
     *  line_2:         string|null,
     *  city:           string,
     *  state:          string|null,
     *  postal_code:    string,
     *  country_code:   string
     * }}
     */
    decodePlaceComponents( placeData ) {
        let components = {};

        for ( let idx in placeData.address_components ) {
            const component = placeData.address_components[ idx ];
            const parsed = this.extractImportantPlaceComponent( component );
            components[ parsed.type ] = parsed.value;
        }

        return components;
    }

    /**
     * 
     * @param {google.maps.places.AddressComponent} addressComponent 
     * @return {{
     *   type: string,
     *   value: string|null
     * }}
     */
    extractImportantPlaceComponent( addressComponent ) {
        // Building number.
        if ( addressComponent.types.includes( "street_number" ) ) {
            return {
                type:   "line_1",
                value:  addressComponent.long_name
            };
        }

        // Street.
        if ( addressComponent.types.includes( "route" ) ) {
            return {
                type:   "line_2",
                value:  addressComponent.long_name
            };
        }

        // City.
        if ( addressComponent.types.includes( "postal_town" ) ) {
            return {
                type:   "city",
                value:  addressComponent.long_name
            };
        }

        // State.
        if ( addressComponent.types.includes( "administrative_area_level_2" ) ) {
            return {
                type:   "state",
                value:  addressComponent.long_name
            };
        }

        // Post code.
        if ( addressComponent.types.includes( "postal_code" ) ){
            return {
                type:   "postal_code",
                value:  addressComponent.long_name
            };
        }

        // Country.
        if ( addressComponent.types.includes( "country" ) ) {
            return {
                type:   "country_code",
                value:  addressComponent.short_name // Short name is the code
            };
        }

        return {
            type: "invalid-" + addressComponent.types[ 0 ],
            value: addressComponent.long_name
        };
    }
}