"use strict";

export default class DonorAddress {
    /**
     * The object reference code, result of HasReferenceCode trait.
     * @var {string|null} reference_code
     */
    reference_code = null;

    /**
     * The line 1.
     * @var {string|null} street1
     */
    street1         = null;
    
    /**
     * The line 2.
     * @var {string|null} street2
     */
    street2         = null;

    /**
     * The city.
     * @var {string|null} city
     */
    city            = null;

    /**
     * The state.
     * @var {string|null} state
     */
    state           = null;

    /**
     * The postal code.
     * @var {string|null} postal_code
     */
    postal_code     = null;

    /**
     * The country code.
     * @var {string|null} country_code
     */
    country_code    = null;

    /**
     * Class constructor
     */
    constructor ( 
        reference_code  = null,
        street1         = null,
        street2         = null,
        city            = null,
        state           = null,
        postal_code     = null,
        country_code    = null
    ) {
        this.reference_code = reference_code;
        this.street1        = street1;
        this.street2        = street2;
        this.city           = city;
        this.state          = state;
        this.postal_code    = postal_code;
        this.country_code   = country_code;
    }

    /**
     * Delete an address, given the instance of the main application.
     * @var {Application} app
     */
    async delete( app ) {
        return app.services.DeleteAddressService.deleteAddress( this.reference_code );
    }

    /**
     * Construct a new Donor instance from any object by copying the properties.
     * @param {Object} object 
     */
    static constructFrom( object ) {
        const donor = new DonorAddress;
        const keys = Object.keys( object );
        const possibleKeys = [
            "reference_code", "street1", "street2",
            "city", "state", "postal_code", "country_code"
        ];

        // Copy object in.
        for ( let idx in possibleKeys ) {
            const possibleKey = possibleKeys[ idx ];
            if ( keys.includes( possibleKey ) ) {
                donor[ possibleKey ] = object[ possibleKey ];
            }
        }

        return donor;
    }
}