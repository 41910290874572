"use strict";

import NetworkTransport from "../classes/NetworkTransport";

/**
 * Handles PayPal one-off payments.
 */
export default class GoCardlessSubscriptionService {
    /**
     * Parent application instance.
     * 
     * @var {App} app
     */
    app = null;

    /**
     * Constructor
     * 
     * @param {App} app 
     */
    constructor( app ) {
        this.app = app;
    }

    /**
     * Creates an order from the known App[lication] data.
     * 
     * @return {Promise<{redirect_url: string|null}>}
     */
    async createSubscription( donationData, tsToken ) {
        const transport = new NetworkTransport( {
            donor:          this.app.customer.reference,
            address:        this.app.customer.address_reference,
            amount:         donationData.amount,
            adminPercent:   donationData.has_admin_fee ? donationData.amount_admin_fee : 0,
            appealId:       donationData.appeal_id,
            isAnonymous:    donationData.is_anonymous,
            appealMessage:  donationData.appeal_message,
            donation_type:  donationData.donation_type,
            is_gift_aid:    donationData.is_gift_aid,
            currency:       donationData.currency,
            reoccurrence:   donationData.frequency_reoccurring, 
            termType:       "open", // If a user changes this manually then so be it. we just won't collect their money for as long.
            termLength:     0, // Zero means infinity in this context.
            customFields:   this.app.services.CustomFieldsService.collectCustomFieldValues(),
            projectId:      donationData.sub_project ?? donationData.project,

            isCompany:      donationData.is_company,
            companyName:    donationData.company_name,

            _tsToken:       tsToken
        } );

        return transport.sendTo( "gocardless/subscription" );
    }
}