"use strict";

import NetworkTransport from "../classes/NetworkTransport";
import DonationData     from "../alpine/DonationData";

/**
 * Handles PayPal one-off payments.
 */
export default class PayPalOneOffService {
    /**
     * Parent application instance.
     * 
     * @var {App} app
     */
    app = null;

    /**
     * Constructor
     * 
     * @param {App} app 
     */
    constructor( app ) {
        this.app = app;
    }

    /**
     * Creates an order from Alpine donation data.
     * @param {DonationData} donationData 
     * @param {string} tsToken 
     * @returns {Promise<{redirect_url:string|null}>}
     */
    async createOrderFromDonationData( donationData, tsToken ) {
        return new NetworkTransport( {
            donor:          this.app.customer.reference,
            address:        this.app.customer.address_reference,
            amount:         donationData.amount,
            adminPercent:   donationData.has_admin_fee ? donationData.amount_admin_fee : 0,
            appealId:       donationData.appeal_id,
            isAnonymous:    donationData.is_anonymous,
            appealMessage:  donationData.appeal_message,
            donation_type:  donationData.donation_type,
            is_gift_aid:    donationData.is_gift_aid,
            currency:       donationData.currency,
            customFields:   this.app.services.CustomFieldsService.collectCustomFieldValues(),
            projectId:      donationData.sub_project ?? donationData.project,
            
            isCompany:      donationData.is_company,
            companyName:    donationData.company_name,
            
            _tsToken:       tsToken
        } ).sendTo( "paypal/one-off" );
    }
}