"use strict";

import APIStatic from "./APIStatic";
import $ from "jquery";

export default /*abstract*/ class NetworkTransport {
    /**
     * Known list of field names.
     * 
     * @var {Array}
     */
    _fields = [];

    /**
     * 
     * @param {Object|null|Array} fields The fields to copy into this object.
     * @returns 
     */
    constructor( fields = null ) {
        if ( ( ! fields ) || ( fields.length ) ) {
            return;
        }

        // Copy the input fields over.
        Object.keys( fields ).forEach( ( function ( fieldKey ) { 
            this[ fieldKey ] = fields[ fieldKey ];
            this._fields.push( fieldKey );
        } ).bind( this ) );
    }

    /**
     * Return the data of the transport.
     * 
     * @return {Object}
     */
    data() { 
        var object = {};
        
        // Converts `this` away from it's class type, removes all methods basically.
        Object.keys( this ).forEach( ( function ( objectKey ) {
            object[ objectKey ] = this[ objectKey ];
        } ).bind( this ) );

        return object;
    }

    /**
     * Converts our fields and such to "networkable" data.
     * 
     * @return {Object}
     */
    toNetworkableData( includeCSRF = true, exludeFields = true, generateTimestamp = true ) {
        var data = this.data();

        // Add CSRF token when requested.
        if ( includeCSRF ) {
            data[ "_token" ] = APIStatic.csrf();
        }

        // Remove the _fields value.
        if ( exludeFields ) {
            delete data[ '_fields' ];
        }

        // Generate the attached timestamp field, if wanted.
        if ( generateTimestamp ) {
            data[ "timestamp" ] = Math.round( Date.now() / 1000.0 );
        }

        return data;
    }

    /**
     * Sends this objects data to a given API endpoint.
     * 
     * @param {String} endpoint 
     */
    async sendTo( endpoint, method = "POST" ) {
        return $.ajax( {
            url:        APIStatic.endpoint( endpoint ),
            method:     method,
            dataType:   "JSON",
            data:       this.toNetworkableData()
        } );
    }
}