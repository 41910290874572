"use strict";

import NetworkTransport from "../classes/NetworkTransport";
import DonationData     from "../alpine/DonationData";
import CFTurnstileService from "./CFTurnstileService";

/**
 * Handles Stripe one-off payments.
 */
export default class StripeOneOffService {
    /**
     * The current stripe JS instance.
     * 
     * @var {stripe.Stripe}
     */
    stripe = null;

    /**
     * Parent application instance.
     * 
     * @type {App} app
     */
    app = null;

    /**
     * Service constructor.
     * 
     * @param {App}             app     The parent application instance.
     * @param {stripe.Stripe}   stripe 
     */
    constructor( app, stripe ) {
        this.app        = app;
        this.stripe     = stripe;
    }

    /**
     * Gets the current stripe instance.
     * 
     * @returns {stripe.Stripe}
     */
    getStripe() {
        return this.stripe;
    }

    /**
     * Creates a payment intent from the Alpine donation data.
     * @param {DonationData}    donationData 
     * @param {string}          tsToken The turnstile verification token.
     * @returns {Promise<{payment_intent:{id:string,client_secret:null}|null}|null>}
     */
    async createPaymentIntentFromDonationData( donationData, tsToken ) {
        return new NetworkTransport( {
            donor:          this.app.customer.reference,
            address:        this.app.customer.address_reference,
            amount:         donationData.amount,
            adminPercent:   donationData.has_admin_fee ? donationData.amount_admin_fee : 0,
            appealId:       donationData.appeal_id,
            isAnonymous:    donationData.is_anonymous,
            appealMessage:  donationData.appeal_message,
            donation_type:  donationData.donation_type,
            is_gift_aid:    donationData.is_gift_aid,
            currency:       donationData.currency,
            customFields:   this.app.services.CustomFieldsService.collectCustomFieldValues(),
            projectId:      donationData.sub_project ?? donationData.project,
            
            isCompany:      donationData.is_company,
            companyName:    donationData.company_name,

            _tsToken:       tsToken
        } )
            .sendTo( "stripe/one-off" );
    }
}