"use strict";

import NetworkTransport from "../classes/NetworkTransport";

export default class StoreCustomerService {
    /**
     * Parent application instance.
     * 
     * @var {App} app
     */
    app = null;

    /**
     * Constructor
     * 
     * @param {App} app 
     */
    constructor( app ) {
        this.app = app;
    }

    /**
     * 
     * @param {string} title 
     * @param {string} first_name 
     * @param {string} last_name 
     * @param {string} email 
     * @param {string} phone 
     * @param {string} address_line_1 
     * @param {string} address_line_2 
     * @param {string} city 
     * @param {string} region 
     * @param {string} postal_code 
     * @param {string} country_code 
     * @returns {Promise<
     *  {
     *      donor:string|null,
     *      address:string|null
     *  } | {
     *      error:string
     *  }
     * >}
     */
    async storeCustomer(
        title,
        first_name,
        last_name,
        email,
        phone,
        address_line_1,
        address_line_2,
        city,
        region,
        postal_code,
        country_code
    ) {
        const transport = new NetworkTransport( {
            title:          title,
            first_name:     first_name,
            last_name:      last_name,
            email:          email,
            phone:          phone,
            address_line_1: address_line_1,
            address_line_2: address_line_2,
            city:           city,
            region:         region,
            postal_code:    postal_code,
            country_code:   country_code,

            _tsToken:       this.app.services.CFTurnstileService.latestTurnstileToken
        } );

        return transport.sendTo( "store-customer" );
    }
}