"use strict";

import NetworkTransport from "../classes/NetworkTransport";
import Application from "../main/Application";
import CFTurnstileService from "./CFTurnstileService";

/**
 * Service for creating new fundraising appeals.
 */
export default class AppealCreateService {
    /**
     * Parent application instance.
     * 
     * @var {Application|null} app
     * @type {Application|null}
     */
    app = null;

    /**
     * Cloudflare Turnstil service.
     * 
     * @var {CFTurnstileService|null} cf
     * @type {CFTurnstileService|null}
     */
    cf = null;

    /**
     * Class constructor.
     */
    constructor ( app, cf ) { 
        this.app = app;
        this.cf = cf;
    }

    /**
     * Creates a new fundraising appeal using data provided by the appeal creation flow.
     * @param {Object} data 
     * @param {Function} processCompleted CB.
     */
    async createAppeal( data, processCompleted ) {
        /**
         * Perform any `data` transformations here.
         */

        // .... 

        this.cf.getToken( ( async function ( token ) { 
            processCompleted( await this.onTurnstileCompleted( data, token ) );
        } ).bind( this ) );
    }

    /**
     * Once turnstile has returned it's data, we send everthing through to the actual endpoint
     * @param {any} data 
     * @param {any} token 
     * @returns 
     */
    async onTurnstileCompleted( data, token ) { 
        data = { // Combine the Turnstile token into the appeal creation.
            ...data,
            _tsToken: token
        };

        // Send the data over.
        return ( new NetworkTransport( data ) )
                .sendTo( "create-appeal" );
    }
}