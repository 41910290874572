"use strict";

import $ from "jquery";
import AppealCreateFlowController from "../controllers/AppealCreateFlowController";
import utl_html_decode_entities, { utl_slugify, utl_instant_file_upload } from "../util";
import Application from "../main/Application";
import Project from "../classes/Project";
import toastr from "toastr";
import Quill from "quill";

/**
 * Essentially, the default image. This should be changed to pass validation.
 * @var {string}
 */
const PLACEHOLDER_IMAGE = "https://placehold.co/320x320";

/**
 * Number of milliseconds to debounce the custom URL checker for.
 * @var {number}
 */
const CUSTOM_URL_CHECK_DEBOUNCE = 500;

/**
 * Minimum number of giving levels that must be selected for an appeal to be created.
 * @var {number}
 */
const MINIMUM_GIVING_LEVEL_COUNT = 1;

/**
 * 
 */
const AppealCreateData = {

    /**
     * The current flow page.
     */
    currentPage: "basic-details",

    /**
     * @type {AppealCreateFlowController|null}
     */
    flowController: null,

    /**
     * Appeal data.
     */
    appeal: {
        /**
         * The name of the appeal.
         */
        name:                   null,

        /**
         * The tagline of the appeal,
         */
        tagline:                null,

        /**
         * The currency code / identifier for this appeal.
         */
        currency:               null,

        /**
         * The project.
         */
        project:                null,

        /**
         * The total amount to be raised.
         */
        goal:                   null,

        /**
         * Does the appeal have an end date?
         */
        has_end_date:           false,

        /**
         * Does the appeal have a fundraising target?
         */
        has_fundraising_target: false,

        /**
         * If it does, then this controls it.
         */
        end_date:               null,

        /**
         * Custom URL for the page. If this is empty or null then there isn't a custom URL.
         */
        custom_url:             null,

        /**
         * Is this donation being made in memory of someone?
         */
        is_in_memory:           false,

        /**
         * If the above is true, then what is the name of this person.
         */
        in_memory_name:         null,

        /**
         * If the above above is true, then the user can provide a description of the person.
         */
        in_memory_description:  null,

        /**
         * Has the owner of the campaign given consent for marketing?
         */
        marketing_consent:      false,

        /**
         * Are we using the template?
         */
        is_using_template:      true,

        /**
         * The user-entered story content. This auto-sets to template when template button is clicked.
         */
        story_content:          "",

        /**
         * The customised story content. This is just used so the user doesn't lose their content when they 
         * click on template and click back to "Write my own".
         */
        custom_story_content:   "",

        /**
         * Selected giving levels.
         */
        giving_levels:          [],

        /**
         * The actual appeal URL, once created.
         */
        url:                    null,

        /**
         * The appeals edit URL.
         */
        edit_url:               null,

        /**
         * The campaigns image.
         */
        image_url:              PLACEHOLDER_IMAGE,

        /**
         * The media ID of the campaign image.
         */
        file_id:                null,

        /**
         * Whether the appeal has a parent or not.
         */
        has_parent:             false,

        /**
         * The reference code of the parent appeal, if there is one.
         */
        parent_reference:       null,

        is_team_appeal:         false,
    },

    /**
     * List of possible URLs for this appeal.
     */
    appeal_urls: {
        page:               null, // Main page URL.
        edit:               null, // Owners edit URL.
        share_linkedin:     null, // LinkedIn URL.
        share_twitter_x:    null, // Twitter URL.
        share_facebook:     null, // Facebook URL.
        share_copy:         null  // Manual copy URL
    },

    /**
     * Is an image being uploaded?
     */
    uploading_image:        false,

    /**
     * Is the campaign information being uploaded, finally?
     */
    uploading_data:         false,

    /**
     * The custom URL slug.
     */
    custom_url_slug_placeholder: null,

    /**
     * Is the custom URL validated through the API endpoint?
     */
    custom_url_valid: false,

    /**
     * True when the user-entered slug is valid.
     */
    slugIsValid: false,

    /**
     * True when the user-entered slug has been used already.
     */
    slugIsInvalid: false,

    /**
     * Defines hooks to run when validating the steps in the flow, where access to data here is necessary.
     */
    validationHookDefinitions: {},
    
    /**
     * Generates the custom URL.
     */
    generateCustomUrl: function () { 
        return utl_slugify( this.appeal.name ?? "" );
    },
    
    /**
     * Alpine component initialisation.
     */
    init: function () {
        // When the project changes, we update our data for story template and giving level display / selection.
        this.$watch( "appeal.project", this.onProjectSelected.bind( this ) );

        // When the appeal name changes, we update the custom URL placeholder.
        this.$watch( "appeal.name", $.debounce( 500, this.onAppealNameChanged.bind( this ) ) );
        
        // When the appeals custom URL is changed, make sure it's okay to be used.
        this.$watch( "appeal.custom_url", $.debounce( CUSTOM_URL_CHECK_DEBOUNCE, this.onCustomURLChanged.bind( this ) ) );

        /**
         * Setup basic detail validation.
         */
        this.validationHookDefinitions[ "basic-details" ] = [
            ( function ( ) { 
                return true;
            } ).bind( this ) 
        ];

        /**
         * Setup goals validation.
         */
        this.validationHookDefinitions[ "goals" ] = [ 
            ( function ( ) {
                return true;
            } ).bind( this ) 
        ];

        /**
         * Setup story validation.
         */
        this.validationHookDefinitions[ "story" ] = [
            ( function ( ) {
                /**
                 * To-do on server side: make sure the uploaded image actually originates from the website, otherwise we can mess things up massively.
                 */
                if ( this.appeal.image_url === PLACEHOLDER_IMAGE ) {
                    toastr.warning( "Please add an appeal image." );
                    return false;
                }

                if ( utl_html_decode_entities( this.appeal.story_content ).trim().length === 0 ) {
                    toastr.warning( "Please enter a story." );
                    return false;
                }
                
                if ( ( ( ( this.appeal.custom_url?.length ?? 0 ) > 0 ) && ( ! this.slugIsValid ) ) || ( ( this.appeal.custom_url?.length ?? 0 ) === 0 ) ) {
                    toastr.warning( "Please enter a valid page URL." );
                    return false;
                }

                return true;
            } ).bind( this ) 
        ];

        /**
         * Setup giving levels validation.
         */
        this.validationHookDefinitions[ "giving-levels" ] = [ 
            ( function ( ) {
                // Validate that
                const nGivingLevels = this.appeal.giving_levels?.length;
                return !!( nGivingLevels && ( nGivingLevels >= MINIMUM_GIVING_LEVEL_COUNT ) );
            } ).bind( this )
        ];

        this.initDOM();
    },

    /**
     * Runs after DOM is initialised.
     */
    initDOM: function () {
        var templateEditor;
        var customEditor;

        const intervalId = setInterval( ( function () { 
            templateEditor = this.flowController?.app?.textEditorController?.getEditor( "appeal-template-editor" );
            customEditor = this.flowController?.app?.textEditorController?.getEditor( "appeal-custom-editor" );

            if ( ( ! customEditor ) || ( ! templateEditor ) ) {
                console.log( "Editors not located.. continuing." );
                return;
            }

            if ( ! customEditor ) {
                console.log( "Waiting for richeditor initialisation." );
                return;
            }

            console.log( "Editors located!" );

            clearInterval( intervalId );
            
            templateEditor.on( "text-change", ( function () {
                this.appeal.story_content = templateEditor.getSemanticHTML();
            } ).bind( this ) );
    
            customEditor.on( "text-change", ( function () {
                this.appeal.story_content = customEditor.getSemanticHTML();
            } ).bind( this ) );
    
            if ( window.parentAppeal ) {
                this.appeal.story_content = window.parentAppeal.story;
                editor.container.querySelector( ".ql-editor" ).innerHTML = ( this.appeal.story_content + "\n" );
    
                this.appeal.has_parent = true;
                this.appeal.parent_reference = window.parentAppeal.reference;
    
                /** @type {Project} project */
                const project = this.flowController.app.getProject( window.parentAppeal.project );
                project.story = window.parentAppeal.story;
                this.appeal.project = project.reference;
    
                // Setup default giving levels since the project changed.
                this.appeal.giving_levels = [];
                Object.keys( project.givingLevels ).forEach( ( function ( element ) {
                    this.appeal.giving_levels.push( element );
                } ).bind( this ) );
                console.log( this.appeal.giving_levels );
            }
        } ).bind( this ), 750 );

    },

    /**
     * Alpine component de-initialisation.
     */
    destroy: function () {},

    /**
     * Move to the next page, if we can.
     */
    nextPage: function () {
        this.flowController.nextPage( this.validationHookDefinitions );
    },

    /**
     * Move to the previous page, if we can.
     */
    previousPage: function () { 
        this.uploading_data = false;
        this.flowController.previousPage();
    },

    /**
     * Once all validation has passed, this will allow for the final upload.
     */
    finalUpload: function () { 
        if ( this.slugIsInvalid ) {
            console.error( "User cannot proceed as slug is marked invalid." );
            return;
        }

        if ( ! this.flowController.validatePage( "giving-levels" ) ) {
            console.error( "User cannot proceed as giving levels page was not validated." );
            return;
        }

        if ( ! AppealCreateFlowController.runValidationHooks( this.validationHookDefinitions, "giving-levels" ) ) {
            console.error( "Giving levels could not be validated." );
            toastr.warning( "Giving levels failed to validate." );
            return;
        }

        this.uploading_data = true;
        this.flowController.finalUpload();
    },

    /**
     * Call this once final data is uploaded.
     * @param {{status: boolean,page_url:string|null,edit_url:string|null}|null} finalUploadData
     */
    finalise: function ( finalUploadData ) {
        if ( ! finalUploadData ) {
            toastr.warning( "Something went wrong while uploading your information. See console" );
            return;
        }

        if ( ! finalUploadData.status ) {
            toastr.warning( "Something went wrong while uploading your information. See console" );
            console.error( finalUploadData );
            return;
        }

        this.appeal_urls.page = finalUploadData.page_url;
        this.appeal_urls.edit = finalUploadData.edit_url;
        this.currentPage = "completed";
    },

    /**
     * When a project is selected, then we set the template story content.
     */
    onProjectSelected: function () {
        /** @type {Application} app */
        const app = this.flowController.app;
        if ( ! app ) {
            console.error( "AppealCreateData.flowController.app is not set?" );
            return;
        }

        /** @type {Project} project */
        const project = app.getProject( this.appeal.project );
        var projectStory = null;
        if ( ! project ) {
            console.warn( `No such project: ${ this.appeal.project }` );
            projectStory = "";
        } else {
            projectStory = project.story;

            // Setup default giving levels since the project changed.
            this.appeal.giving_levels = [];
            Object.keys( project.givingLevels ).forEach( ( function ( element ) {
                this.appeal.giving_levels.push( element );
            } ).bind( this ) );
        }

        /** @type {Quill} */
        const editor = this.flowController?.app?.textEditorController?.getEditor( "appeal-template-editor" );
        if ( ! editor ) {
            console.warn( "Failed to find editor: #appeal-template-editor" );
            return;
        }

        // Set the editor HTML.
        editor.container.querySelector( ".ql-editor" ).innerHTML = ( projectStory + "\n" );

        if ( this.appeal.is_using_template ) {
            this.appeal.custom_story_content = projectStory;
        }

        this.appeal.story_content = projectStory;
    },

    /**
     * Update the custom URLs when name of campaign is changed.
     */
    onAppealNameChanged: function () {
        this.custom_url_slug_placeholder = this.generateCustomUrl();
        this.appeal.custom_url = this.custom_url_slug_placeholder;
        this.onCustomURLChanged();
    },

    /**
     * When the custom URL is updated we have to validate that it hasn't yet been taken.
     */
    onCustomURLChanged: function () {
        this.slugIsInvalid  = false;
        this.slugIsValid    = false;

        const slugChecker = this.flowController.app?.services?.AppealSlugCheckService;
        if ( ! slugChecker ) {
            console.error( "Custom slug checker has not loaded." );
            return;
        }

        // This has no effect if the value has already been transformed.
        this.appeal.custom_url = utl_slugify( this.appeal.custom_url );
        
        // Run the check.
        slugChecker
            .checkIfSlugIsTaken( this.appeal.custom_url )
            .then( this.onCustomURLChecked.bind( this ) );
    },

    /**
     * Handles the custom URL checking response.
     * @param {Boolean} isTaken 
     */
    onCustomURLChecked: function ( isTaken ) {
        if ( isTaken ) {
            this.slugIsInvalid = true;
            this.slugIsValid = false;
        } else {
            this.slugIsInvalid = false;
            this.slugIsValid = true;
        }
    },

    /**
     * Called when the user chooses to use the template.
     */
    onSelectTemplate: function () { 
        this.appeal.is_using_template = true;

        /** @type {Application} app */
        const app = this.flowController.app;
        if ( ! app ) {
            console.error( "AppealCreateData.flowController.app is not set?" );
            return;
        }

        /** @type {Project} project */
        const project = app.getProject( this.appeal.project );
        var projectStory = null;
        if ( ! project ) {
            if ( window.parentAppeal?.story ) {
                projectStory = window.parentAppeal.story;
            } else {
                console.warn( `No such project: ${ this.appeal.project }` );
                projectStory = "";
            }
        } else {
            projectStory = project.story;
        }

        // Copy the template story content into the story entry.
        this.appeal.story_content = projectStory;

        /** @type {FroalaEditor|null} Note the type is actually Proxy<FroalaEditor>|null. */
        const editor = this.flowController?.app?.textEditorController?.getEditor( "appeal-template-editor" );
        if ( ! editor ) {
            console.warn( "Failed to find editor: #appeal-template-editor" );
            return;
        }

        editor.container.querySelector( ".ql-editor" ).innerHTML = ( this.appeal.story_content + "\n" );
    },

    /**
     * Called when the user chooses the custom story.
     */
    onSelectCustomStory: function () {
        this.appeal.is_using_template = false;

        const editor = this.flowController?.app?.textEditorController?.getEditor( "appeal-custom-editor" );
        if ( ! editor ) {
            console.warn( "Failed to find editor: #appeal-custom-editor" );
            return;
        }

        this.appeal.story_content = editor.getSemanticHTML();

        editor.container.querySelector( ".ql-editor" ).innerHTML = ( "\n" );
    },

    /**
     * Export the appeal data to a JS Object, which can then be further moved to JSON et.
     */
    export: function () {
        return JSON.parse( JSON.stringify( this.appeal ) );
    },

    /**
     * Shows the campaign image upload modal.
     */
    startFileUpload: function ()  {
        utl_instant_file_upload( 
            "appeals", 
            this.onSavedAppealImage.bind( this ),
            this.onImageUploadStarted.bind( this )
        );
    },

    /**
     * Called when an image upload has begun.
     */
    onImageUploadStarted: function () {
        // Enable loading spinner.
        this.uploading_image = true;
    },

    /**
     * Called once a file is uploaded as expected.
     * @param {Object} jsonResponse 
     */
    onSavedAppealImage: function ( jsonResponse ) {
        // Disable "loading" spinner.
        this.uploading_image = false;

        if ( ! jsonResponse.success ) {
            console.error( jsonResponse.error );
            toastr.warning( "Failed to upload image: " + jsonResponse.error );
            return;
        }

        this.appeal.image_url   = jsonResponse.location;
        this.appeal.image_id    = jsonResponse.file_id;
    },
    
    /**
     * Gets the loading SVG spinner.
     */
    getLoadingSVG: function () {
        return "Uploading....";
    },

    /**
     * Grabs the appeal data as a JSON object.
     * @returns {Object<TODO: DEFINE THE TYPE HERE.>}
     */
    appealData: function () {
        this.appeal.goal = this.appeal.has_fundraising_target ? this.appeal.goal : 0;
        
        return JSON.parse( 
            JSON.stringify( this.appeal ) 
        );
    }
};

export {
    AppealCreateData as default
};