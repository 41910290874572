"use strict";

import intlTelInput from "intl-tel-input";
import $ from "jquery";
import { utl_patch_number_input } from "../util";
import Application from "../main/Application";

/**
 * Controls phone number formatting.
 */
export default class PhoneController {
    /**
     * The list of phone inputs on the page (or more specifically, their intlTelInput instances.)
     */
    phoneInputs = {};

    /**
     * @type {Application|null}
     */
    app = null;

    /**
     * 
     * @param {Application|null} app 
     */
    constructor ( app ) {
        this.app = app;

        $( this.onReady.bind( this ) );
    }

    /**
     * Sets up intlTelInput.js stuff.
     */
    onReady() {
        if ( ! $( `input[type="tel"]` ).length ) {
            console.warn( "No attribute of `input[type=\"tel\"]` found." );
            return;
        }

        // Iterate on phone inputs and setup, and store.
        $( `input[type="tel"]` ).each( ( function ( i, element ) {
            this.initialisePhoneInput( element.id );
        } ).bind( this ) );
    }

    /**
     * Initialise a new phone input.
     */
    initialisePhoneInput( id ) {
        const element = $( `#${ id }` ).get( 0 );

        if ( ! element ) {
            throw "Attempt to initialise new phone input on missing element.";
        }

        // New intlTelInput instnance.
        const phone = intlTelInput( element, this.#getPhoneOptions() ) 

        // Bind focusout to format on click-off.
        $( element ).on( "focusout", this.#onFocusOutPhoneInput.bind( this, element, phone ) );

        // Push to our list of phone inputs.
        this.phoneInputs[ element.id ] = phone;

        utl_patch_number_input();

        console.info( `Initialised PhoneController with ID: #${ element.id }` );
    }

    /**
     * Grabs a phone instance.
     * @param {string} id 
     * @returns {intlTelInput.Plugin}
     */
    get( id ) {
        return this.phoneInputs[ id ];
    }

    /**
     * Sets up the default phone options.
     * @return {intlTelInput.Options}
     */
    #getPhoneOptions() {
        /** @type {intlTelInput.Options} */
        return {
            initialCountry:     "gb",
            utilsScript:        "../js/intl-tel-input/utils.js",
            autoPlaceholder:    "aggressive",
            formatOnDisplay:    true,
            nationalMode:       false
        };
    }

    /**
     * Called on focusout of a phone input.
     * 
     * @param {HTMLInputElement} element 
     * @param {intlTelInput} phoneInstance 
     */
    #onFocusOutPhoneInput( element, phoneInstance ) {
        // Ensure utils have been loaded (they are not synchronous to this call.)
        if ( typeof intlTelInputUtils === "undefined" ) {
            return;
        }

        // E164 is the server requirement, ideally.
        const formattedNumber = phoneInstance.getNumber( intlTelInputUtils.numberFormat.E164 );
        
        if ( typeof formattedNumber === "string" ) {
            phoneInstance.setNumber( formattedNumber );
        }
    }
}