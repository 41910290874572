"use strict";

/**
 * Some wrappers for the console output for our debugging uses. Allows reporting more obvious issues.
 * 
 * This was used for wrapping console.log with toastr, but toastr is no longer required outside of dev work. 
 * We'll keep this together and use it if we need to hook some more interesting admin reporting or something..
 */
export default class Feedback {
    /**s
     * Generic console error, with toastr output.
     * @param  {...any} args 
     */
    static error( ...args ) {
        return console.error( args );
    }

    /**
     * Generic console warning, with toastr output.
     * @param  {...any} args 
     * @returns 
     */
    static warn( ...args ) {
        return console.warn( args );
    }

    /**
     * Generic console warning, with toastr output.
     * @param  {...any} args 
     * @returns 
     */
    static notice( ...args ) {
        args.map( arg => console.log( arg ) );
    }

    /**
     * Generic console info.
     * @param  {...any} args 
     */
    static info( ...args ) {
        return console.info( args );
    }

    /**
     * Generic console log.
     * @param  {...any} args
     */
    static log( ...args ) {
        return console.log( args );
    }

    /**
     * Generic console debug.
     * @param  {...any} args
     */
    static debug( ...args ) {
        return console.debug( args );
    }

    /**
     * Generic console success.
     * @param  {...any} args 
     */
    static success( ...args ) {
        args.forEach( arg => { 
            console.log( "%c%s", "color: ##009c27", arg );
        } );
        return true;
    }
}